import { getToken, setToken, removeToken } from '@/utils/auth'
import { login,loginMoni, personalGetInfo } from '@/api/login'

const user = {
  state: {
    userInfo: {
      balance: '0'
    },
    token: getToken(),
    margin: 0, // 保证金
    totalProfit: 0, // 净值
    feeProfit: 0, // 保证金比例
    canMargin: 0, // 可用保证金
  },
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_BALANCE: (state, balance) => {
      state.userInfo.balance = balance||0
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_MARGIN: (state, margin) => {
      state.margin = margin
    },
    SET_TOTALPROFIT: (state, totalProfit) => {
      state.totalProfit = totalProfit
    },
    SET_FEEPROFIT: (state, feeProfit) => {
      state.feeProfit = feeProfit
    },
    SET_CANMARGIN: (state, canMargin) => {
      state.canMargin = canMargin
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit("SET_USERINFO", userInfo);
    },
    setBalance({ commit }, balance) {
      commit("SET_BALANCE", balance);
    },
    setMargin({ commit }, data) {
      commit("SET_MARGIN", data);
    },
    setTotalProfit({ commit }, data) {
      commit("SET_TOTALPROFIT", data);
    },
    setFeeProfit({ commit }, data) {
      commit("SET_FEEPROFIT", data);
    },
    setCanMargin({ commit }, data) {
      commit("SET_CANMARGIN", data);
    },
    // 登录
    Login({ commit }, userInfo) {
      const email = userInfo.email.trim()
      const password = userInfo.password
      // const code = userInfo.code
      // const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login({email, password}).then(res => {
          // 设置token
          setToken(res.data)
          commit('SET_TOKEN', res.data)
          resolve()
          // personalGetInfo().then(response => {
          //   // 设置用户信息
          //   commit('SET_USERINFO', response.data)
          //   resolve()
          // }).catch(error => {
          //   reject(error)
          // })
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 模拟账户登录
    moniLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginMoni().then(res => {
          // 设置token
          setToken(res.data)
          commit('SET_TOKEN', res.data)
          // personalGetInfo().then(response => {
          //   // 设置用户信息
          //   commit('SET_USERINFO', response.data)
          //   resolve()
          // }).catch(error => {
          //   reject(error)
          // })
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    BackLogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          // commit('SET_COMMONAPI', '')
          // commit('SET_COMMONIMG', '')
          // commit('SET_COMMONWS', '')
          removeToken()
          if (window.ws) {
            window.ws.removeQueueViewObj('websocket');
            window.ws.destroy();
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        // commit('SET_COMMONAPI', '')
        // commit('SET_COMMONIMG', '')
        // commit('SET_COMMONWS', '')
        removeToken()
        if (window.ws) {
          window.ws.removeQueueViewObj('websocket');
          window.ws.destroy();
        }
        resolve()
      })
    }

  }
};

export default user;
