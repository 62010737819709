import request from "../utils/request";

export function login(data) {
  return request({
    url: '/app/user/login',
    method: "post",
    data: data
  });
}

// 模拟账户
export function loginMoni() {
  return request({
    url: '/app/user/login/FORMAL',
    method: "post"
  });
}

// 用户信息
export function personalGetInfo(data) {
  return request({
      url:"/app/user/personal/find",
      method:"get",
      data:data,
  })
}

// 客服
export function dealerDetail() {
  return request({
    url: '/app/user/personal/dealerDetail',
    method: "get"
  });
}