<template>
  <router-view />
</template>

<script>
// import websocket from "./utils/websocket/websocket" // websocket数据页面数据接入
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  data() {
        return {
          dataList: [],
          dealers: '',
          dealersImg: '',
          dealersWs: '',
          dealersName: ''
      }
    },
  computed: {
    ...mapGetters([
      'theme',
      'get_common_api',
      'get_common_img',
      'get_common_ws',
      'get_common_name',
      'get_common_link'
    ])
  },
  created(){
    if(this.theme) {
      document.body.classList.toggle("dark");
    }
    let lang = sessionStorage.getItem("lang")||'en'
    let get_common_api = sessionStorage.getItem("get_common_api")
        let get_common_img = sessionStorage.getItem("get_common_img")
        let get_common_ws = sessionStorage.getItem("get_common_ws")
        let get_common_name = sessionStorage.getItem("get_common_name")
        let get_common_link = sessionStorage.getItem("get_common_link")
        let get_common_api_list = sessionStorage.getItem("get_common_api_list")

        this.set_lang(lang)
        this.setCommonApi(get_common_api)
        this.setCommonImg(get_common_img)
        this.setCommonWs(get_common_ws)
        this.setCommonName(get_common_name)
        this.setCommonLink(get_common_link)
        this.setCommonApiList(JSON.parse(get_common_api_list))
  },
  methods: {
    ...mapActions({
      set_lang: 'set_lang',
      // 交易商域名
      setCommonApi: 'setCommonApi',
      // 交易商图片域名
      setCommonImg: 'setCommonImg',
      // 交易商ws域名
      setCommonWs: 'setCommonWs',
      // 交易商名称
      setCommonName: 'setCommonName',
      // 推广注册页地址
      setCommonLink: 'setCommonLink',
      setCommonApiList: 'setCommonApiList'
    }),
  }
}
</script>
