export default {
	"bank": {
		"delete": "supprimer carte bancaire",
		"five": "Veuillez entrer SWIF",
		"fiveName": "adresse de la carte bancaire",
		"four": "Veuillez saisir l'adresse de la banque du bénéficiaire",
		"fourName": "Numéro de carte bancaire",
		"one": "Veuillez entrer le nom du destinataire",
		"oneName": "type de devise",
		"sixName": "ajouter une carte bancaire",
		"three": "Veuillez saisir le numéro de compte destinataire",
		"threeName": "Nom de la carte bancaire",
		"two": "Veuillez saisir le nom de la banque bénéficiaire",
		"twoName": "Nom"
	},
	"common": {
		"about": "à propos de nous",
		"logout": "se déconnecter",
		"noData": "pas de données",
		"search": "recherche",
		"systemSet": "installation",
		"title": "France"
	},
	"depositWith": {
		"czName": "soumettre une recharge",
		"eightName": "Comptes débiteurs",
		"fiveName": "adresse de la banque",
		"fourName": "Nom de banque",
		"nineName": "Montant de la recharge",
		"one": "Veuillez entrer le montant de la recharge",
		"oneName": "Transfert d'argent international",
		"sevenName": "bénéficiaire",
		"sixName": "SWITF",
		"tenFour": "Code QR du portefeuille",
		"tenName": "montant du paiement",
		"tenOne": "télécharger le bon de paiement",
		"tenThree": "adresse de portefeuille",
		"tenTwo": "réseau d'argent",
		"threeName": "type de devise",
		"two": "Montant réel du paiement",
		"twoName": "monnaie numérique",
		"txName": "soumettre le retrait"
	},
	"information": {
		"email": "Veuillez contacter le client pour modification",
		"name": "Veuillez entrer un pseudo",
		"phone": "Veuillez entrer le numéro de téléphone"
	},
	"inOutMoney": {
		"fiveName": "la quantité",
		"fourName": "échouer",
		"oneName": "tous",
		"sevenName": "temps",
		"sixName": "État",
		"threeName": "succès",
		"twoName": "a vérifier"
	},
	"login": {
		"demo": "compte démo",
		"forget": "oublier le mot de passe",
		"inpTips1": "adresse e-mail",
		"inpTips2": "mot de passe",
		"login": "Connexion",
		"reg1": "merci d'entrer votre email",
		"reg2": "veuillez entrer le mot de passe",
		"register": "enregistrer",
		"remember": "se souvenir du mot de passe",
		"tipMsg": "Pas de compte"
	},
	"marketPairs": {
		"collection": "ma collection",
		"pairs": "code marchandise",
		"purchasePrice": "prix d'achat",
		"sellPrice": "prix de vente",
		"tradeType": "variété commerciale"
	},
	"marketTrade": {
		"balance": "équilibre:",
		"bookOrder": "commander",
		"buy": "Buy",
		"buying": "Acheter",
		"currentRate": "Effet de levier actuel :",
		"limit": "commande commandée",
		"margin": "Marge:",
		"marginAvailable": "Marge disponible :",
		"marginRatio": "Taux de marge :",
		"market": "ordre du marché",
		"price": "prix",
		"quantity": "quantité",
		"sell": "Sell",
		"selling": "Acheter ou tomber",
		"stopLoss": "arrêter la perte",
		"takeProfit": "tirer profit",
		"volume": "valeur nette :"
	},
	"notification": {
		"msg1": "Modifié avec succès",
		"msg10": "Veuillez ajouter un portefeuille",
		"msg11": "Veuillez changer de mode de paiement",
		"msg12": "renouveler",
		"msg13": "Veuillez ajouter une carte bancaire",
		"msg14": "réinitialisation réussie",
		"msg15": "Réviser",
		"msg16": "supprimer",
		"msg17": "fermer une position",
		"msg18": "Opération réussie",
		"msg19": "Le prix stop loss doit être supérieur au prix d'ouverture",
		"msg2": "Soumis avec succès",
		"msg20": "Le prix de prise de profit doit être inférieur au prix d'ouverture",
		"msg21": "Le prix de la commande ne peut pas être vide",
		"msg22": "Opération réussie",
		"msg23": "code de verification envoyé",
		"msg3": "Ajouté avec succès",
		"msg4": "succès de l'inscription",
		"msg5": "supprimé avec succès",
		"msg6": "téléchargé avec succès",
		"msg7": "Bien sûr",
		"msg8": "Annuler",
		"msg9": "Êtes-vous sûr de supprimer le portefeuille"
	},
	"orderHistory": {
		"cancel": "Annulation",
		"close": "fermer une position",
		"closedOrders": "Ordre de clôture",
		"closing": "Fermeture",
		"commissionedOrder": "commande commandée",
		"endTime": "date de fin",
		"openOrders": "ordre de position ouverte",
		"operate": "fonctionner",
		"orderHistory": "Ordre historique",
		"startTime": "date de début",
		"thead": [
			"code marchandise",
			"nombre impair",
			"taper",
			"prix d'ouverture",
			"horaire d'ouverture",
			"prix de la marque",
			"S/L",
			"T/P",
			"frais de gestion",
			"Gains et pertes flottants",
			"prix des commissions",
			"temps de commission",
			"Le dernier prix",
			"Heure de fermeture",
			"temps de transaction",
			"prix final",
			"Le nombre de transactions",
			"quantité",
			"profit et perte"
		]
	},
	"password": {
		"one": "Veuillez entrer le mot de passe d'origine",
		"oneName": "ancien mot de passe",
		"three": "Veuillez saisir à nouveau le nouveau mot de passe",
		"threeName": "saisir à nouveau le mot de passe",
		"two": "Veuillez entrer un nouveau mot de passe",
		"twoName": "nouveau mot de passe"
	},
	"personLeft": {
		"bank": "carte bancaire",
		"information": "informations personnelles",
		"invite": "Inviter des amis",
		"news": "notifier",
		"order": "enregistrement de commande",
		"recharge": "recharger",
		"recordIn": "dossier de dépôt",
		"recordOut": "Dossier de retrait",
		"updatePassword": "changer le mot de passe",
		"verified": "Vérifié",
		"wallet": "portefeuille virtuel",
		"withdraw": "retirer"
	},
	"personTitle": {
		"country": "nation",
		"edit": "Réviser",
		"emailTitle": "Poster",
		"idCard": "numéro d'identification",
		"idCardFront": "ID avant",
		"idCardFront1": "Le verso du document",
		"name": "Nom",
		"nameTitle": "Surnom",
		"phoneTitle": "numéro de téléphone",
		"status": "non certifié",
		"status0": "certification",
		"status1": "vérifié",
		"status2": "authentification rejetée",
		"status3": "non certifié",
		"submit": "soumettre"
	},
	"readVer": {
		"codeOk": "Captcha est disponible",
		"email": "Veuillez contacter le client pour modification",
		"errerLog": "Erreur de code de vérification",
		"idCard": "Veuillez entrer le numéro d'identification",
		"name": "Veuillez entrer un pseudo"
	},
	"register": {
		"getVerification": "obtenir le code de vérification",
		"inpTips1": "Poster",
		"inpTips2": "mot de passe",
		"inpTips3": "le code de vérification",
		"inpTips4": "Code d'invitation",
		"login": "Connexion",
		"msg1": "Je suis d'accord",
		"msg2": "termes et conditions",
		"msg3": "Vous avez déjà un compte?",
		"reg1": "merci d'entrer votre email",
		"reg2": "veuillez entrer le mot de passe",
		"reg3": "veuillez entrer le code de vérification",
		"reg4": "Veuillez entrer le code d'invitation",
		"reg5": "Bien envoyé",
		"register": "Créer un compte",
		"resetVerification": "Réacquérir"
	},
	"reset": {
		"btn": "réinitialiser",
		"getSend": "obtenir le code de vérification",
		"inpTips": "Veuillez saisir votre adresse e-mail",
		"msg1": "Rappelez-vous votre mot de passe ?",
		"msg2": "Connectez-vous ici",
		"reset": "réinitialiser le mot de passe"
	},
	"time": {
		"15min": "15 minutes",
		"1H": "1 heure",
		"1min": "1 minute",
		"30min": "30 minutes",
		"4H": "4 heures",
		"5day": "5 jours",
		"5min": "5 minutes",
		"day": "Ligne quotidienne",
		"moon": "Ligne de lune",
		"second": "partage de temps",
		"week": "Hebdomadaire"
	},
	"userWith": {
		"eightName": "Comptes débiteurs",
		"fiveName": "adresse de la banque",
		"fourName": "Nom de banque",
		"nineName": "Montant de retrait",
		"one": "Veuillez entrer le montant du retrait",
		"oneName": "Transfert d'argent international",
		"sevenName": "bénéficiaire",
		"sixName": "SWIFT",
		"tenFour": "Code QR du portefeuille",
		"tenName": "Montant du reçu",
		"tenOne": "télécharger le bon de paiement",
		"tenThree": "adresse de portefeuille",
		"tenTwo": "réseau d'argent",
		"threeName": "Devise du bénéficiaire",
		"two": "Montant réel reçu",
		"twoName": "monnaie numérique"
	},
	"wallet": {
		"add": "ajouter un portefeuille",
		"delete": "supprimer le portefeuille",
		"fourName": "Code QR du portefeuille",
		"one": "Veuillez entrer le réseau de devises (par exemple TRC20)",
		"oneName": "type de devise",
		"threeName": "adresse de portefeuille",
		"two": "Veuillez entrer l'adresse du portefeuille",
		"twoName": "réseau d'argent"
	}
}