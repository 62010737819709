import axios from "axios";
import { getToken } from '@/utils/auth'

import { ElMessage } from 'element-plus'
import store from '@/store'
import router from "@/router";

axios.defaults.baseURL = sessionStorage.getItem("get_common_api");
axios.defaults.timeout = 100000;
// axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  config => {
    let { params } = config;
    if (params) {
      let keys = Object.keys(params);
      for (let key of keys) {
        if (params[key] === "") params[key] = null;
      }
    }
    let url = config.url;
    if (config.method === "get" && params) {
      if (url.indexOf("?") !== -1) {
        url += "&";
      } else {
        url += "?";
      }
      let keys = Object.keys(params);
      for (let key of keys) {
        if (!isNull(params[key]) && params[key] !== "") {
          url += `${key}=${encodeURIComponent(params[key])}&`;
        }
      }
    }
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers.platform = "traderPc";
    config.headers.lang = store.getters.get_lang;
    // 设置登录态end
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    try {
    const code = response.data.code || 200;
    const msg = response.data.msg
      if (code == '401') {
        store.dispatch('FedLogOut').then(() => {
          router.push({ path: "/" })
        })
        return Promise.reject('error')
      } else if (code !== 200) {
        ElMessage({
          message: msg,
          type: 'error',
        })
        return Promise.reject('error')
      } else {
        return response.data
      }
    } catch (e) {
      throw e;
    }
  },
  error => {
    let { message } = error;
    if (message == "Network Error") {
      message = "Abnormal backend interface connection";
    } else if (message.includes("timeout")) {
      message = "System interface request timed out";
    } else if (message.includes("Request failed with status code")) {
      message = "System error" + message.substr(message.length - 3);
    }
    store.dispatch('FedLogOut').then(() => {
      router.push({ path: "/" })
    })
    ElMessage({
      message: message,
      type: 'error',
    })
    return Promise.reject(error);
  }
);

export function isNull(value) {
  return value === null || value === undefined;
}

export default axios;