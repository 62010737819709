export default {
	"bank": {
		"delete": "deletar cartão de banco",
		"five": "Insira o SWIF",
		"fiveName": "endereço do cartão bancário",
		"four": "Insira o endereço do banco do beneficiário",
		"fourName": "Número do cartão bancário",
		"one": "Por favor, insira o nome do destinatário",
		"oneName": "tipo de moeda",
		"sixName": "adicionar cartão de banco",
		"three": "Insira o número da conta receptora",
		"threeName": "Nome do cartão bancário",
		"two": "Insira o nome do banco beneficiário",
		"twoName": "Nome"
	},
	"common": {
		"about": "sobre nós",
		"logout": "sair",
		"noData": "sem dados",
		"search": "procurar",
		"systemSet": "configurar",
		"title": "Português"
	},
	"depositWith": {
		"czName": "enviar recarga",
		"eightName": "Contas a receber",
		"fiveName": "endereço do banco",
		"fourName": "nome do banco",
		"nineName": "Valor da recarga",
		"one": "Por favor, insira o valor da recarga",
		"oneName": "Transferência Internacional de Dinheiro",
		"sevenName": "beneficiário",
		"sixName": "SWITF",
		"tenFour": "Código QR da carteira",
		"tenName": "valor do pagamento",
		"tenOne": "carregar comprovante de pagamento",
		"tenThree": "endereço da carteira",
		"tenTwo": "rede de dinheiro",
		"threeName": "tipo de moeda",
		"two": "Valor real do pagamento",
		"twoName": "moeda digital",
		"txName": "enviar retirada"
	},
	"information": {
		"email": "Entre em contato com o cliente para modificação",
		"name": "Insira um apelido",
		"phone": "Por favor, insira o número de telefone"
	},
	"inOutMoney": {
		"fiveName": "Quantidade",
		"fourName": "falhar",
		"oneName": "todos",
		"sevenName": "tempo",
		"sixName": "estado",
		"threeName": "sucesso",
		"twoName": "a ser revisado"
	},
	"login": {
		"demo": "conta demo",
		"forget": "esqueça a senha",
		"inpTips1": "endereço de email",
		"inpTips2": "senha",
		"login": "Conecte-se",
		"reg1": "por favor insira seu e-mail",
		"reg2": "por favor digite a senha",
		"register": "registro",
		"remember": "lembrar senha",
		"tipMsg": "sem conta"
	},
	"marketPairs": {
		"collection": "minha coleção",
		"pairs": "Código de mercadoria",
		"purchasePrice": "preço de compra",
		"sellPrice": "preço de venda",
		"tradeType": "variedade de negociação"
	},
	"marketTrade": {
		"balance": "equilíbrio:",
		"bookOrder": "fazer um pedido",
		"buy": "Buy",
		"buying": "comprando",
		"currentRate": "Alavancagem atual:",
		"limit": "pedido comissionado",
		"margin": "Margem:",
		"marginAvailable": "Margem disponível:",
		"marginRatio": "Taxa de margem:",
		"market": "ordem de mercado",
		"price": "preço",
		"quantity": "quantidade",
		"sell": "Sell",
		"selling": "Comprando ou caindo",
		"stopLoss": "parar a perda de",
		"takeProfit": "obter lucros",
		"volume": "patrimônio líquido:"
	},
	"notification": {
		"msg1": "Modificado com sucesso",
		"msg10": "Por favor, adicione carteira",
		"msg11": "Troque o método de pagamento",
		"msg12": "renovar",
		"msg13": "Adicione um cartão bancário",
		"msg14": "redefinir com sucesso",
		"msg15": "Rever",
		"msg16": "excluir",
		"msg17": "fechar uma posição",
		"msg18": "operação bem sucedida",
		"msg19": "O preço de stop loss deve ser maior que o preço de abertura",
		"msg2": "submetido com sucesso",
		"msg20": "O preço de take profit deve ser menor que o preço de abertura",
		"msg21": "O preço do pedido não pode estar vazio",
		"msg22": "operação bem sucedida",
		"msg23": "Código de verificação enviado",
		"msg3": "Adicionado com sucesso",
		"msg4": "Registro bem sucedido",
		"msg5": "excluído com sucesso",
		"msg6": "carregado com sucesso",
		"msg7": "Claro",
		"msg8": "Cancelar",
		"msg9": "Tem certeza de excluir a carteira"
	},
	"orderHistory": {
		"cancel": "Cancelamento",
		"close": "fechar uma posição",
		"closedOrders": "Pedido de fechamento",
		"closing": "fechando",
		"commissionedOrder": "pedido comissionado",
		"endTime": "data final",
		"openOrders": "ordem de posição aberta",
		"operate": "operar",
		"orderHistory": "ordem histórica",
		"startTime": "data de início",
		"thead": [
			"Código de mercadoria",
			"número ímpar",
			"tipo",
			"preço de abertura",
			"Tempo de abertura",
			"preço de marca",
			"S/L",
			"T/P",
			"taxa de manuseio",
			"Lucros e perdas flutuantes",
			"preço de comissão",
			"tempo de comissão",
			"Preço final",
			"Horário de encerramento",
			"tempo de transação",
			"preço final",
			"O número de transações",
			"quantidade",
			"lucros e perdas"
		]
	},
	"password": {
		"one": "Por favor, digite a senha original",
		"oneName": "Senha Antiga",
		"three": "Por favor, digite a nova senha novamente",
		"threeName": "Coloque a senha novamente",
		"two": "Insira uma nova senha",
		"twoName": "Nova Senha"
	},
	"personLeft": {
		"bank": "cartão do banco",
		"information": "informações pessoais",
		"invite": "convide amigos",
		"news": "notificar",
		"order": "registro de pedido",
		"recharge": "recarrega",
		"recordIn": "registro de depósito",
		"recordOut": "registro de retirada",
		"updatePassword": "alterar a senha",
		"verified": "Verificado",
		"wallet": "carteira virtual",
		"withdraw": "retirar"
	},
	"personTitle": {
		"country": "nação",
		"edit": "Rever",
		"emailTitle": "Correspondência",
		"idCard": "número de identidade",
		"idCardFront": "ID frontal",
		"idCardFront1": "O verso do documento",
		"name": "Nome",
		"nameTitle": "Apelido",
		"phoneTitle": "número de telefone",
		"status": "não certificado",
		"status0": "certificação",
		"status1": "verificado",
		"status2": "autenticação rejeitada",
		"status3": "não certificado",
		"submit": "enviar"
	},
	"readVer": {
		"codeOk": "Captcha está disponível",
		"email": "Entre em contato com o cliente para modificação",
		"errerLog": "Erro no código de verificação",
		"idCard": "Insira o número de identificação",
		"name": "Insira um apelido"
	},
	"register": {
		"getVerification": "obter código de verificação",
		"inpTips1": "Correspondência",
		"inpTips2": "senha",
		"inpTips3": "Código de verificação",
		"inpTips4": "Código de Convite",
		"login": "Conecte-se",
		"msg1": "Concordo",
		"msg2": "termos e Condições",
		"msg3": "já tem uma conta?",
		"reg1": "por favor insira seu e-mail",
		"reg2": "por favor digite a senha",
		"reg3": "por favor insira o código de verificação",
		"reg4": "Por favor insira o código de convite",
		"reg5": "Enviado com sucesso",
		"register": "Registre uma conta",
		"resetVerification": "readquirir"
	},
	"reset": {
		"btn": "reiniciar",
		"getSend": "obter código de verificação",
		"inpTips": "Por favor, indique o seu endereço de e-mail",
		"msg1": "Lembra da sua senha?",
		"msg2": "entre aqui",
		"reset": "redefinir senha"
	},
	"time": {
		"15min": "15 minutos",
		"1H": "1 hora",
		"1min": "1 minuto",
		"30min": "30 minutos",
		"4H": "4 horas",
		"5day": "5 dias",
		"5min": "5 minutos",
		"day": "linha diária",
		"moon": "linha da lua",
		"second": "compartilhamento de tempo",
		"week": "Semanalmente"
	},
	"userWith": {
		"eightName": "Contas a receber",
		"fiveName": "endereço do banco",
		"fourName": "nome do banco",
		"nineName": "Quantidade retirada",
		"one": "Por favor, insira o valor da retirada",
		"oneName": "Transferência Internacional de Dinheiro",
		"sevenName": "beneficiário",
		"sixName": "SWIFT",
		"tenFour": "Código QR da carteira",
		"tenName": "Valor do recibo",
		"tenOne": "carregar comprovante de pagamento",
		"tenThree": "endereço da carteira",
		"tenTwo": "rede de dinheiro",
		"threeName": "Moeda do Beneficiário",
		"two": "Valor real recebido",
		"twoName": "moeda digital"
	},
	"wallet": {
		"add": "adicionar carteira",
		"delete": "deletar carteira",
		"fourName": "Código QR da carteira",
		"one": "Por favor, insira a rede de moeda (por exemplo, TRC20)",
		"oneName": "tipo de moeda",
		"threeName": "endereço da carteira",
		"two": "Insira o endereço da carteira",
		"twoName": "rede de dinheiro"
	}
}