
import { loadLanguageAsync } from '../../utils/i18n'
const lang = {
  state: {
    lang: 'en'
  },
  mutations: {
    SET_LANG(state, lang) {
      state.lang = lang;
      loadLanguageAsync(lang)
    },
  },
  actions: {
    set_lang({ commit }, lang) {
      commit("SET_LANG", lang);
    }
  }
};

export default lang;
