export default {
	"bank": {
		"delete": "ลบบัตรธนาคาร",
		"five": "กรุณากรอก SWIF",
		"fiveName": "ที่อยู่บัตรธนาคาร",
		"four": "กรุณากรอกที่อยู่ธนาคารของผู้รับผลประโยชน์",
		"fourName": "หมายเลขบัตรธนาคาร",
		"one": "กรุณากรอกชื่อผู้รับ",
		"oneName": "ประเภทสกุลเงิน",
		"sixName": "เพิ่มบัตรธนาคาร",
		"three": "กรุณากรอกเลขที่บัญชีรับเงิน",
		"threeName": "ชื่อบัตรธนาคาร",
		"two": "กรุณากรอกชื่อธนาคารผู้รับผลประโยชน์",
		"twoName": "ชื่อ"
	},
	"common": {
		"about": "เกี่ยวกับเรา",
		"logout": "ออกจากระบบ",
		"noData": "ไม่มีข้อมูล",
		"search": "ค้นหา",
		"systemSet": "ติดตั้ง",
		"title": "ประเทศไทย"
	},
	"depositWith": {
		"czName": "ส่งการเติมเงิน",
		"eightName": "ลูกหนี้การค้า",
		"fiveName": "ที่อยู่ธนาคาร",
		"fourName": "ชื่อธนาคาร",
		"nineName": "จำนวนเงินที่เติม",
		"one": "กรุณากรอกจำนวนเงินที่เติม",
		"oneName": "การโอนเงินระหว่างประเทศ",
		"sevenName": "ผู้รับเงิน",
		"sixName": "สวท",
		"tenFour": "รหัส QR กระเป๋าสตางค์",
		"tenName": "จำนวนเงินที่ชำระ",
		"tenOne": "อัปโหลดใบสำคัญจ่าย",
		"tenThree": "ที่อยู่กระเป๋าเงิน",
		"tenTwo": "เครือข่ายเงิน",
		"threeName": "ประเภทสกุลเงิน",
		"two": "ยอดชำระจริง",
		"twoName": "สกุลเงินดิจิทัล",
		"txName": "ส่งถอน"
	},
	"information": {
		"email": "โปรดติดต่อลูกค้าเพื่อทำการแก้ไข",
		"name": "กรุณาใส่ชื่อเล่น",
		"phone": "กรุณากรอกหมายเลขโทรศัพท์"
	},
	"inOutMoney": {
		"fiveName": "จำนวนเงิน",
		"fourName": "ล้มเหลว",
		"oneName": "ทั้งหมด",
		"sevenName": "เวลา",
		"sixName": "สถานะ",
		"threeName": "ความสำเร็จ",
		"twoName": "ที่จะได้รับการตรวจสอบ"
	},
	"login": {
		"demo": "บัญชีทดลอง",
		"forget": "ลืมรหัสผ่าน",
		"inpTips1": "ที่อยู่อีเมล",
		"inpTips2": "รหัสผ่าน",
		"login": "เข้าสู่ระบบ",
		"reg1": "กรุณาใส่อีเมลของคุณ",
		"reg2": "กรุณาใส่รหัสผ่าน",
		"register": "ลงทะเบียน",
		"remember": "จดจำรหัสผ่าน",
		"tipMsg": "ไม่มีบัญชี"
	},
	"marketPairs": {
		"collection": "คอลเลกชันของฉัน",
		"pairs": "รหัสสินค้า",
		"purchasePrice": "ราคาซื้อ",
		"sellPrice": "ราคาขาย",
		"tradeType": "การซื้อขายที่หลากหลาย"
	},
	"marketTrade": {
		"balance": "สมดุล:",
		"bookOrder": "สั่งซื้อสินค้า",
		"buy": "Buy",
		"buying": "กำลังซื้อขึ้น",
		"currentRate": "เลเวอเรจปัจจุบัน:",
		"limit": "คำสั่งว่าจ้าง",
		"margin": "ระยะขอบ:",
		"marginAvailable": "มาร์จิ้นที่ใช้ได้:",
		"marginRatio": "อัตรากำไรขั้นต้น:",
		"market": "คำสั่งของตลาด",
		"price": "ราคา",
		"quantity": "ปริมาณ",
		"sell": "Sell",
		"selling": "ซื้อหรือตก",
		"stopLoss": "หยุดการสูญเสีย",
		"takeProfit": "ขายทำกำไร",
		"volume": "รายได้สุทธิ:"
	},
	"notification": {
		"msg1": "แก้ไขเรียบร้อยแล้ว",
		"msg10": "กรุณาเพิ่มกระเป๋าสตางค์",
		"msg11": "กรุณาเปลี่ยนวิธีการชำระเงิน",
		"msg12": "ต่ออายุ",
		"msg13": "โปรดเพิ่มบัตรธนาคาร",
		"msg14": "รีเซ็ตสำเร็จ",
		"msg15": "ปรับปรุงใหม่",
		"msg16": "ลบ",
		"msg17": "ปิดตำแหน่ง",
		"msg18": "การดำเนินการที่ประสบความสำเร็จ",
		"msg19": "ราคาหยุดการขาดทุนจะต้องมากกว่าราคาเปิด",
		"msg2": "ส่งเรียบร้อยแล้ว",
		"msg20": "ราคาทำกำไรจะต้องน้อยกว่าราคาเปิด",
		"msg21": "ต้องระบุราคาคำสั่งซื้อ",
		"msg22": "การดำเนินการที่ประสบความสำเร็จ",
		"msg23": "ส่งรหัสยืนยันแล้ว",
		"msg3": "เพิ่มเรียบร้อยแล้ว",
		"msg4": "การลงทะเบียนสำเร็จ",
		"msg5": "ลบเรียบร้อยแล้ว",
		"msg6": "อัปโหลดสำเร็จ",
		"msg7": "แน่นอน",
		"msg8": "ยกเลิก",
		"msg9": "คุณแน่ใจหรือที่จะลบกระเป๋าเงิน"
	},
	"orderHistory": {
		"cancel": "การยกเลิก",
		"close": "ปิดตำแหน่ง",
		"closedOrders": "ปิดรับออเดอร์",
		"closing": "กำลังปิด",
		"commissionedOrder": "คำสั่งว่าจ้าง",
		"endTime": "วันที่สิ้นสุด",
		"openOrders": "คำสั่งเปิดตำแหน่ง",
		"operate": "ดำเนินงาน",
		"orderHistory": "คำสั่งทางประวัติศาสตร์",
		"startTime": "วันที่เริ่มต้น",
		"thead": [
			"รหัสสินค้า",
			"เลขคี่",
			"พิมพ์",
			"ราคาเปิด",
			"เวลาเปิด",
			"ราคาเครื่องหมาย",
			"เอส/แอล",
			"ที/พี",
			"ค่าธรรมเนียมการจัดการ",
			"กำไรขาดทุนลอยตัว",
			"ราคาค่าคอมมิชชั่น",
			"เวลาคอมมิชชั่น",
			"ราคาปิด",
			"เวลาปิด",
			"เวลาในการทำธุรกรรม",
			"ราคาสุดท้าย",
			"จำนวนธุรกรรม",
			"ปริมาณ",
			"กำไรและขาดทุน"
		]
	},
	"password": {
		"one": "กรุณากรอกรหัสผ่านเดิม",
		"oneName": "รหัสผ่านเก่า",
		"three": "กรุณาใส่รหัสผ่านใหม่อีกครั้ง",
		"threeName": "ใส่รหัสผ่านอีกครั้ง",
		"two": "กรุณาใส่รหัสผ่านใหม่",
		"twoName": "รหัสผ่านใหม่"
	},
	"personLeft": {
		"bank": "บัตรเครดิตธนาคาร",
		"information": "ข้อมูลส่วนบุคคล",
		"invite": "เชิญเพื่อน ๆ",
		"news": "แจ้ง",
		"order": "บันทึกการสั่งซื้อ",
		"recharge": "เติมเงิน",
		"recordIn": "บันทึกการฝากเงิน",
		"recordOut": "บันทึกการถอนเงิน",
		"updatePassword": "เปลี่ยนรหัสผ่าน",
		"verified": "ตรวจสอบแล้ว",
		"wallet": "กระเป๋าเงินเสมือน",
		"withdraw": "ถอน"
	},
	"personTitle": {
		"country": "ชาติ",
		"edit": "ปรับปรุงใหม่",
		"emailTitle": "จดหมาย",
		"idCard": "หมายเลขประจำตัวประชาชน",
		"idCardFront": "ไอดีด้านหน้า",
		"idCardFront1": "ด้านหลังของเอกสาร",
		"name": "ชื่อ",
		"nameTitle": "ชื่อเล่น",
		"phoneTitle": "หมายเลขโทรศัพท์",
		"status": "ไม่ได้รับการรับรอง",
		"status0": "การรับรอง",
		"status1": "ตรวจสอบแล้ว",
		"status2": "การรับรองความถูกต้องถูกปฏิเสธ",
		"status3": "ไม่ได้รับการรับรอง",
		"submit": "ส่ง"
	},
	"readVer": {
		"codeOk": "แคปต์ชาได้",
		"email": "โปรดติดต่อลูกค้าเพื่อทำการแก้ไข",
		"errerLog": "รหัสยืนยันผิดพลาด",
		"idCard": "กรุณากรอกเลขประจำตัวประชาชน",
		"name": "กรุณาใส่ชื่อเล่น"
	},
	"register": {
		"getVerification": "รับรหัสยืนยัน",
		"inpTips1": "จดหมาย",
		"inpTips2": "รหัสผ่าน",
		"inpTips3": "รหัสยืนยัน",
		"inpTips4": "รหัสการเชิญ",
		"login": "เข้าสู่ระบบ",
		"msg1": "ฉันเห็นด้วย",
		"msg2": "ข้อกำหนดและเงื่อนไข",
		"msg3": "มีบัญชีอยู่แล้ว?",
		"reg1": "กรุณาใส่อีเมลของคุณ",
		"reg2": "กรุณาใส่รหัสผ่าน",
		"reg3": "กรุณากรอกรหัสยืนยัน",
		"reg4": "กรุณากรอกรหัสเชิญ",
		"reg5": "ส่งเรียบร้อย",
		"register": "ลงทะเบียนบัญชี",
		"resetVerification": "ซื้อใหม่"
	},
	"reset": {
		"btn": "รีเซ็ต",
		"getSend": "รับรหัสยืนยัน",
		"inpTips": "กรุณากรอกอีเมลของคุณ",
		"msg1": "จำรหัสผ่านของคุณ?",
		"msg2": "เข้าสู่ระบบที่นี่",
		"reset": "รีเซ็ตรหัสผ่าน"
	},
	"time": {
		"15min": "15 นาที",
		"1H": "1 ชั่วโมง",
		"1min": "1 นาที",
		"30min": "30 นาที",
		"4H": "4 ชั่วโมง",
		"5day": "5 วัน",
		"5min": "5 นาที",
		"day": "สายรายวัน",
		"moon": "สายจันทร์",
		"second": "การแบ่งเวลา",
		"week": "รายสัปดาห์"
	},
	"userWith": {
		"eightName": "ลูกหนี้การค้า",
		"fiveName": "ที่อยู่ธนาคาร",
		"fourName": "ชื่อธนาคาร",
		"nineName": "จำนวนเงินที่ถอนออก",
		"one": "กรุณากรอกจำนวนเงินที่ถอน",
		"oneName": "การโอนเงินระหว่างประเทศ",
		"sevenName": "ผู้รับเงิน",
		"sixName": "SWIFT",
		"tenFour": "รหัส QR กระเป๋าสตางค์",
		"tenName": "จำนวนเงินที่ได้รับ",
		"tenOne": "อัปโหลดใบสำคัญจ่าย",
		"tenThree": "ที่อยู่กระเป๋าเงิน",
		"tenTwo": "เครือข่ายเงิน",
		"threeName": "สกุลเงินผู้รับผลประโยชน์",
		"two": "จำนวนเงินที่ได้รับจริง",
		"twoName": "สกุลเงินดิจิทัล"
	},
	"wallet": {
		"add": "เพิ่มกระเป๋าสตางค์",
		"delete": "ลบกระเป๋าเงิน",
		"fourName": "รหัส QR กระเป๋าสตางค์",
		"one": "กรุณาระบุเครือข่ายสกุลเงิน (เช่น TRC20)",
		"oneName": "ประเภทสกุลเงิน",
		"threeName": "ที่อยู่กระเป๋าเงิน",
		"two": "กรุณากรอกที่อยู่กระเป๋าเงิน",
		"twoName": "เครือข่ายเงิน"
	}
}