import messages from "../i18n"
import { createI18n } from 'vue-i18n';
// import {store} from "../store/index";
// import store from "@/store"

let lang = sessionStorage.getItem("lang")

const i18n = createI18n({
  locale: lang||'en',
  messages,
  silentTranslationWarn: true, // 是否抑制本地化失败时输出的警告
})

/**
 * @description: 异步获取国际化数据,并设置
 * @param {*} lang
 * @return {*}
 */
async function loadLanguageAsync (lang){
	// 语言映射路径
  // let map_ = {
  //   tw: 'tw', // 繁体
  //   en: 'en',// 英语
  //   vi: 'vi',// 越南语
  // }
  return await import(/* webpackChunkName: "lang-[request]" */`../i18n/${lang}`).then(langfile=>{
    // 动态加载对应的语言包
    let langFile = langfile.default;
    // 设置语言信息
    i18n.global.setLocaleMessage(lang,langFile);
    // 设置语种
    i18n.global.locale = lang;
    sessionStorage.setItem("lang", lang);
    return lang;
  })
}

export { i18n, loadLanguageAsync }