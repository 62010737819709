export default {
	"bank": {
		"delete": "удалить банковскую карту",
		"five": "Пожалуйста, введите SWIF",
		"fiveName": "адрес банковской карты",
		"four": "Пожалуйста, введите адрес банка получателя",
		"fourName": "Номер банковской карты",
		"one": "Пожалуйста, введите имя получателя",
		"oneName": "тип валюты",
		"sixName": "добавить банковскую карту",
		"three": "Пожалуйста, введите номер счета получателя",
		"threeName": "Название банковской карты",
		"two": "Пожалуйста, введите название банка получателя",
		"twoName": "Имя"
	},
	"common": {
		"about": "о нас",
		"logout": "выход",
		"noData": "нет данных",
		"search": "поиск",
		"systemSet": "настраивать",
		"title": "Русский"
	},
	"depositWith": {
		"czName": "отправить перезарядку",
		"eightName": "Дебиторская задолженность",
		"fiveName": "Адрес банка",
		"fourName": "название банка",
		"nineName": "Сумма пополнения",
		"one": "Пожалуйста, введите сумму пополнения",
		"oneName": "Международный перевод денег",
		"sevenName": "получатель платежа",
		"sixName": "СВИТФ",
		"tenFour": "QR-код кошелька",
		"tenName": "к оплате",
		"tenOne": "загрузить платежный ваучер",
		"tenThree": "адрес кошелька",
		"tenTwo": "денежная сеть",
		"threeName": "тип валюты",
		"two": "Фактическая сумма платежа",
		"twoName": "цифровая валюта",
		"txName": "отправить отзыв"
	},
	"information": {
		"email": "Пожалуйста, свяжитесь с клиентом для модификации",
		"name": "Пожалуйста, введите псевдоним",
		"phone": "Пожалуйста, введите номер телефона"
	},
	"inOutMoney": {
		"fiveName": "количество",
		"fourName": "неудача",
		"oneName": "все",
		"sevenName": "время",
		"sixName": "состояние",
		"threeName": "успех",
		"twoName": "быть рассмотренным"
	},
	"login": {
		"demo": "демо-счет",
		"forget": "забыть пароль",
		"inpTips1": "Адрес электронной почты",
		"inpTips2": "пароль",
		"login": "Авторизоваться",
		"reg1": "Пожалуйста, введите свой адрес электронной почты",
		"reg2": "пожалуйста введите пароль",
		"register": "регистр",
		"remember": "запомнить пароль",
		"tipMsg": "Нет аккаунта"
	},
	"marketPairs": {
		"collection": "моя коллекция",
		"pairs": "Код товара",
		"purchasePrice": "цена",
		"sellPrice": "цена продажи",
		"tradeType": "торговый сорт"
	},
	"marketTrade": {
		"balance": "баланс:",
		"bookOrder": "разместить заказ",
		"buy": "Buy",
		"buying": "Скупка",
		"currentRate": "Текущее кредитное плечо:",
		"limit": "заказной заказ",
		"margin": "Допуск:",
		"marginAvailable": "Доступная маржа:",
		"marginRatio": "Коэффициент маржи:",
		"market": "рыночный ордер",
		"price": "цена",
		"quantity": "количество",
		"sell": "Sell",
		"selling": "Покупка или падение",
		"stopLoss": "остановить потери",
		"takeProfit": "получать прибыль",
		"volume": "чистая стоимость:"
	},
	"notification": {
		"msg1": "Успешно изменено",
		"msg10": "Пожалуйста, добавьте кошелек",
		"msg11": "Пожалуйста, измените способ оплаты",
		"msg12": "возобновить",
		"msg13": "Пожалуйста, добавьте банковскую карту",
		"msg14": "сброс выполнен успешно",
		"msg15": "Исправлять",
		"msg16": "удалить",
		"msg17": "закрыть позицию",
		"msg18": "Успешная операция",
		"msg19": "Цена стоп-лосса должна быть больше цены открытия.",
		"msg2": "Отправлено успешно",
		"msg20": "Цена тейк-профита должна быть меньше цены открытия.",
		"msg21": "Цена заказа не может быть пустой",
		"msg22": "Успешная операция",
		"msg23": "Код подтверждения отправлен",
		"msg3": "Добавлено успешно",
		"msg4": "Вы успешно зарегистрировались",
		"msg5": "успешно удален",
		"msg6": "загружено успешно",
		"msg7": "Конечно",
		"msg8": "Отмена",
		"msg9": "Вы уверены, что удалили кошелек?"
	},
	"orderHistory": {
		"cancel": "Отмена",
		"close": "закрыть позицию",
		"closedOrders": "Порядок закрытия",
		"closing": "Закрытие",
		"commissionedOrder": "заказной заказ",
		"endTime": "Дата окончания",
		"openOrders": "ордер на открытие позиции",
		"operate": "действовать",
		"orderHistory": "Исторический порядок",
		"startTime": "Дата начала",
		"thead": [
			"Код товара",
			"нечетное число",
			"тип",
			"цена открытия",
			"время открытия",
			"маркировать цену",
			"С/Л",
			"Т/П",
			"плата за обработку",
			"Плавающая прибыль и убыток",
			"комиссионная цена",
			"время комиссии",
			"Цена закрытия",
			"Время закрытия",
			"время транзакции",
			"окончательная цена",
			"Количество транзакций",
			"количество",
			"Прибыль и убыток"
		]
	},
	"password": {
		"one": "Пожалуйста, введите исходный пароль",
		"oneName": "Старый пароль",
		"three": "Пожалуйста, введите новый пароль еще раз",
		"threeName": "введите пароль еще раз",
		"two": "Пожалуйста, введите новый пароль",
		"twoName": "Новый пароль"
	},
	"personLeft": {
		"bank": "банковская карта",
		"information": "персональная информация",
		"invite": "пригласить друзей",
		"news": "поставить в известность",
		"order": "запись заказа",
		"recharge": "перезарядка",
		"recordIn": "депозитная запись",
		"recordOut": "Запись о снятии средств",
		"updatePassword": "изменить пароль",
		"verified": "проверено",
		"wallet": "виртуальный кошелек",
		"withdraw": "отзывать"
	},
	"personTitle": {
		"country": "нация",
		"edit": "Исправлять",
		"emailTitle": "Почта",
		"idCard": "идентификационный номер",
		"idCardFront": "ID спереди",
		"idCardFront1": "Обратная сторона документа",
		"name": "Имя",
		"nameTitle": "Никнейм",
		"phoneTitle": "номер телефона",
		"status": "не сертифицирован",
		"status0": "сертификация",
		"status1": "проверено",
		"status2": "аутентификация отклонена",
		"status3": "не сертифицирован",
		"submit": "представлять на рассмотрение"
	},
	"readVer": {
		"codeOk": "Капча доступна",
		"email": "Пожалуйста, свяжитесь с клиентом для модификации",
		"errerLog": "Ошибка кода подтверждения",
		"idCard": "Пожалуйста, введите идентификационный номер",
		"name": "Пожалуйста, введите псевдоним"
	},
	"register": {
		"getVerification": "получить код подтверждения",
		"inpTips1": "Почта",
		"inpTips2": "пароль",
		"inpTips3": "проверочный код",
		"inpTips4": "Код приглашения",
		"login": "Авторизоваться",
		"msg1": "Я согласен",
		"msg2": "условия и положения",
		"msg3": "У вас уже есть аккаунт?",
		"reg1": "Пожалуйста, введите свой адрес электронной почты",
		"reg2": "пожалуйста введите пароль",
		"reg3": "пожалуйста, введите проверочный код",
		"reg4": "Пожалуйста, введите код приглашения",
		"reg5": "Успешно отправлено",
		"register": "Зарегистрировать аккаунт",
		"resetVerification": "Получить"
	},
	"reset": {
		"btn": "перезагрузить",
		"getSend": "получить код подтверждения",
		"inpTips": "Пожалуйста, введите Ваш адрес электронной почты",
		"msg1": "Помните свой пароль?",
		"msg2": "войти здесь",
		"reset": "Сброс пароля"
	},
	"time": {
		"15min": "15 минут",
		"1H": "1 час",
		"1min": "1 минута",
		"30min": "30 минут",
		"4H": "4 часа",
		"5day": "5 дней",
		"5min": "5 минут",
		"day": "Ежедневная линия",
		"moon": "Линия Луны",
		"second": "совместное времяпровождение",
		"week": "Еженедельно"
	},
	"userWith": {
		"eightName": "Дебиторская задолженность",
		"fiveName": "Адрес банка",
		"fourName": "название банка",
		"nineName": "Сумма вывода",
		"one": "Пожалуйста, введите сумму вывода",
		"oneName": "Международный перевод денег",
		"sevenName": "получатель платежа",
		"sixName": "SWIFT",
		"tenFour": "QR-код кошелька",
		"tenName": "Сумма чека",
		"tenOne": "загрузить платежный ваучер",
		"tenThree": "адрес кошелька",
		"tenTwo": "денежная сеть",
		"threeName": "Валюта получателя",
		"two": "Фактическая полученная сумма",
		"twoName": "цифровая валюта"
	},
	"wallet": {
		"add": "добавить кошелек",
		"delete": "удалить кошелек",
		"fourName": "QR-код кошелька",
		"one": "Пожалуйста, введите валютную сеть (например, TRC20)",
		"oneName": "тип валюты",
		"threeName": "адрес кошелька",
		"two": "Пожалуйста, введите адрес кошелька",
		"twoName": "денежная сеть"
	}
}