export default {
	"bank": {
		"delete": "銀行カードを削除する",
		"five": "SWIFを入力してください",
		"fiveName": "銀行カードの住所",
		"four": "受取銀行の住所を入力してください",
		"fourName": "銀行カード番号",
		"one": "受取人の名前を入力してください",
		"oneName": "通貨の種類",
		"sixName": "銀行カードを追加",
		"three": "受取口座番号を入力してください",
		"threeName": "銀行カード名",
		"two": "受取銀行名を入力してください",
		"twoName": "名前"
	},
	"common": {
		"about": "私たちに関しては",
		"logout": "サインアウト",
		"noData": "データなし",
		"search": "検索",
		"systemSet": "設定",
		"title": "日本"
	},
	"depositWith": {
		"czName": "リチャージを提出する",
		"eightName": "売掛金",
		"fiveName": "銀行の住所",
		"fourName": "銀行名",
		"nineName": "充電量",
		"one": "チャージ金額を入力してください",
		"oneName": "国際送金",
		"sevenName": "受取人",
		"sixName": "SWITF",
		"tenFour": "ウォレットの QR コード",
		"tenName": "支払金額",
		"tenOne": "支払い伝票をアップロードする",
		"tenThree": "ウォレットアドレス",
		"tenTwo": "マネーネットワーク",
		"threeName": "通貨の種類",
		"two": "実際のお支払い金額",
		"twoName": "デジタル通貨",
		"txName": "撤回を提出する"
	},
	"information": {
		"email": "変更はお客様へご連絡ください",
		"name": "ニックネームを入力してください",
		"phone": "電話番号を入力してください"
	},
	"inOutMoney": {
		"fiveName": "総額",
		"fourName": "失敗",
		"oneName": "全て",
		"sevenName": "時間",
		"sixName": "州",
		"threeName": "成功",
		"twoName": "見直される"
	},
	"login": {
		"demo": "デモ口座",
		"forget": "パスワードを忘れた",
		"inpTips1": "電子メールアドレス",
		"inpTips2": "パスワード",
		"login": "ログイン",
		"reg1": "メールアドレスを入力してください",
		"reg2": "パスワードを入力してください",
		"register": "登録",
		"remember": "パスワードを覚える",
		"tipMsg": "アカウントなし"
	},
	"marketPairs": {
		"collection": "私のコレクション",
		"pairs": "商品コード",
		"purchasePrice": "購入金額",
		"sellPrice": "販売価格",
		"tradeType": "取引の種類"
	},
	"marketTrade": {
		"balance": "バランス：",
		"bookOrder": "注文する",
		"buy": "Buy",
		"buying": "買い占め",
		"currentRate": "現在のレバレッジ:",
		"limit": "委託注文",
		"margin": "マージン：",
		"marginAvailable": "利用可能な証拠金:",
		"marginRatio": "証拠金率:",
		"market": "成行注文",
		"price": "価格",
		"quantity": "量",
		"sell": "Sell",
		"selling": "買ったり落ちたり",
		"stopLoss": "ストップロス",
		"takeProfit": "利益を取る",
		"volume": "純資産:"
	},
	"notification": {
		"msg1": "正常に変更されました",
		"msg10": "ウォレットを追加してください",
		"msg11": "お支払い方法を変更してください",
		"msg12": "更新する",
		"msg13": "銀行カードを追加してください",
		"msg14": "リセット成功",
		"msg15": "改訂",
		"msg16": "消去",
		"msg17": "ポジションを閉じる",
		"msg18": "成功した操作",
		"msg19": "ストップロス価格は始値より大きくなければなりません",
		"msg2": "正常に送信されました",
		"msg20": "テイクプロフィット価格は始値よりも低くなければなりません",
		"msg21": "注文価格を空にすることはできません",
		"msg22": "成功した操作",
		"msg23": "確認コードが送信されました",
		"msg3": "正常に追加されました",
		"msg4": "登録完了",
		"msg5": "正常に削除されました",
		"msg6": "正常にアップロードされました",
		"msg7": "もちろん",
		"msg8": "キャンセル",
		"msg9": "本当にウォレットを削除しますか"
	},
	"orderHistory": {
		"cancel": "キャンセル",
		"close": "ポジションを閉じる",
		"closedOrders": "引け順",
		"closing": "閉鎖",
		"commissionedOrder": "委託注文",
		"endTime": "終了日",
		"openOrders": "オープンポジション注文",
		"operate": "操作する",
		"orderHistory": "歴史的順序",
		"startTime": "開始日",
		"thead": [
			"商品コード",
			"奇数",
			"タイプ",
			"始値",
			"開始時間",
			"印価",
			"S/L",
			"T/P",
			"手数料",
			"変動損益",
			"手数料価格",
			"コミッションタイム",
			"終値",
			"閉店時間",
			"取引時間",
			"最終価格",
			"取引回数",
			"量",
			"利益と損失"
		]
	},
	"password": {
		"one": "元のパスワードを入力してください",
		"oneName": "以前のパスワード",
		"three": "新しいパスワードをもう一度入力してください",
		"threeName": "パスワードをもう一度入力してください",
		"two": "新しいパスワードを入力してください",
		"twoName": "新しいパスワード"
	},
	"personLeft": {
		"bank": "バンクカード",
		"information": "個人情報",
		"invite": "友達を招待",
		"news": "通知する",
		"order": "注文記録",
		"recharge": "再充電",
		"recordIn": "預金記録",
		"recordOut": "出金記録",
		"updatePassword": "パスワードを変更する",
		"verified": "検証済み",
		"wallet": "バーチャルウォレット",
		"withdraw": "撤退"
	},
	"personTitle": {
		"country": "国家",
		"edit": "改訂",
		"emailTitle": "郵便",
		"idCard": "ID番号",
		"idCardFront": "IDフロント",
		"idCardFront1": "書類の裏",
		"name": "名前",
		"nameTitle": "ニックネーム",
		"phoneTitle": "電話番号",
		"status": "認定されていません",
		"status0": "認証",
		"status1": "検証済み",
		"status2": "認証が拒否されました",
		"status3": "認定されていません",
		"submit": "送信"
	},
	"readVer": {
		"codeOk": "キャプチャが利用可能です",
		"email": "変更はお客様へご連絡ください",
		"errerLog": "確認コード エラー",
		"idCard": "ID番号を入力してください",
		"name": "ニックネームを入力してください"
	},
	"register": {
		"getVerification": "確認コードを取得する",
		"inpTips1": "郵便",
		"inpTips2": "パスワード",
		"inpTips3": "検証コード",
		"inpTips4": "招待コード",
		"login": "ログイン",
		"msg1": "同意します",
		"msg2": "規約と条件",
		"msg3": "すでにアカウントをお持ちですか？",
		"reg1": "メールアドレスを入力してください",
		"reg2": "パスワードを入力してください",
		"reg3": "確認コードを入力してください",
		"reg4": "招待コードを入力してください",
		"reg5": "正常に送信されました",
		"register": "アカウントを登録",
		"resetVerification": "再取得"
	},
	"reset": {
		"btn": "リセット",
		"getSend": "確認コードを取得する",
		"inpTips": "メールアドレスを入力してください",
		"msg1": "パスワードを覚えていますか？",
		"msg2": "ここでログイン",
		"reset": "パスワードを再設定する"
	},
	"time": {
		"15min": "15分",
		"1H": "1時間",
		"1min": "1分",
		"30min": "30分",
		"4H": "4時間",
		"5day": "5日間",
		"5min": "5分",
		"day": "デイリーライン",
		"moon": "ムーンライン",
		"second": "時間を共有する",
		"week": "毎週"
	},
	"userWith": {
		"eightName": "売掛金",
		"fiveName": "銀行の住所",
		"fourName": "銀行名",
		"nineName": "出金金額",
		"one": "出金金額を入力してください",
		"oneName": "国際送金",
		"sevenName": "受取人",
		"sixName": "SWIFT",
		"tenFour": "ウォレットの QR コード",
		"tenName": "入金額",
		"tenOne": "支払い伝票をアップロードする",
		"tenThree": "ウォレットアドレス",
		"tenTwo": "マネーネットワーク",
		"threeName": "受取通貨",
		"two": "実際に受け取った金額",
		"twoName": "デジタル通貨"
	},
	"wallet": {
		"add": "ウォレットを追加",
		"delete": "ウォレットを削除",
		"fourName": "ウォレットの QR コード",
		"one": "通貨ネットワークを入力してください (例: TRC20)",
		"oneName": "通貨の種類",
		"threeName": "ウォレットアドレス",
		"two": "ウォレットアドレスを入力してください",
		"twoName": "マネーネットワーク"
	}
}