export default {
	"bank": {
		"delete": "eliminare la carta di credito",
		"five": "Inserisci SWIF",
		"fiveName": "indirizzo della carta di credito",
		"four": "Inserisci l'indirizzo della banca del beneficiario",
		"fourName": "Numero di carta bancaria",
		"one": "Inserisci il nome del destinatario",
		"oneName": "tipo di valuta",
		"sixName": "aggiungi carta di credito",
		"three": "Si prega di inserire il numero di conto ricevente",
		"threeName": "Nome della carta bancaria",
		"two": "Inserisci il nome della banca del beneficiario",
		"twoName": "Nome"
	},
	"common": {
		"about": "chi siamo",
		"logout": "disconnessione",
		"noData": "nessun dato",
		"search": "ricerca",
		"systemSet": "impostare",
		"title": "italiano"
	},
	"depositWith": {
		"czName": "inviare ricarica",
		"eightName": "Crediti",
		"fiveName": "indirizzo bancario",
		"fourName": "nome della banca",
		"nineName": "Importo di ricarica",
		"one": "Inserisci l'importo della ricarica",
		"oneName": "Trasferimento di denaro internazionale",
		"sevenName": "beneficiario",
		"sixName": "SWITF",
		"tenFour": "Codice QR del portafoglio",
		"tenName": "importo del pagamento",
		"tenOne": "caricare il buono di pagamento",
		"tenThree": "indirizzo del portafoglio",
		"tenTwo": "rete di denaro",
		"threeName": "tipo di valuta",
		"two": "Importo effettivo del pagamento",
		"twoName": "moneta digitale",
		"txName": "presentare recesso"
	},
	"information": {
		"email": "Si prega di contattare il cliente per la modifica",
		"name": "Per favore inserisci un nickname",
		"phone": "Inserisci il numero di telefono"
	},
	"inOutMoney": {
		"fiveName": "l'importo",
		"fourName": "fallire",
		"oneName": "Tutto",
		"sevenName": "tempo",
		"sixName": "stato",
		"threeName": "successo",
		"twoName": "da rivedere"
	},
	"login": {
		"demo": "conto demo",
		"forget": "dimentica la parola d'ordine",
		"inpTips1": "indirizzo e-mail",
		"inpTips2": "parola d'ordine",
		"login": "Login",
		"reg1": "per favore inserisci la tua email",
		"reg2": "Per favore, inserisci la password",
		"register": "Registrati",
		"remember": "ricordare la password",
		"tipMsg": "Nessun conto"
	},
	"marketPairs": {
		"collection": "la mia collezione",
		"pairs": "codice merceologico",
		"purchasePrice": "prezzo d'acquisto",
		"sellPrice": "prezzo di vendita",
		"tradeType": "varietà commerciale"
	},
	"marketTrade": {
		"balance": "bilancia:",
		"bookOrder": "effettuare un ordine",
		"buy": "Buy",
		"buying": "Acquistare",
		"currentRate": "Leva corrente:",
		"limit": "ordine commissionato",
		"margin": "Margine:",
		"marginAvailable": "Margine disponibile:",
		"marginRatio": "Rapporto di margine:",
		"market": "ordine di mercato",
		"price": "prezzo",
		"quantity": "quantità",
		"sell": "Sell",
		"selling": "Comprare o cadere",
		"stopLoss": "fermare la perdita",
		"takeProfit": "avere un profitto",
		"volume": "patrimonio netto:"
	},
	"notification": {
		"msg1": "Modificato con successo",
		"msg10": "Aggiungi portafoglio",
		"msg11": "Si prega di cambiare metodo di pagamento",
		"msg12": "rinnovare",
		"msg13": "Aggiungi una carta bancaria",
		"msg14": "ripristino riuscito",
		"msg15": "Rivedere",
		"msg16": "eliminare",
		"msg17": "chiudere una posizione",
		"msg18": "Operazione riuscita",
		"msg19": "Il prezzo di stop loss deve essere maggiore del prezzo di apertura",
		"msg2": "Inviato con successo",
		"msg20": "Il prezzo di take profit deve essere inferiore al prezzo di apertura",
		"msg21": "Il prezzo dell'ordine non può essere vuoto",
		"msg22": "Operazione riuscita",
		"msg23": "codice di verifica inviato",
		"msg3": "Aggiunto con successo",
		"msg4": "Registrazione avvenuta",
		"msg5": "eliminato con successo",
		"msg6": "caricato con successo",
		"msg7": "Sicuro",
		"msg8": "Annulla",
		"msg9": "Sei sicuro di eliminare il portafoglio"
	},
	"orderHistory": {
		"cancel": "Cancellazione",
		"close": "chiudere una posizione",
		"closedOrders": "Ordine di chiusura",
		"closing": "Chiusura",
		"commissionedOrder": "ordine commissionato",
		"endTime": "data di fine",
		"openOrders": "ordine di posizione aperta",
		"operate": "operare",
		"orderHistory": "Ordine storico",
		"startTime": "data d'inizio",
		"thead": [
			"codice merceologico",
			"numero dispari",
			"tipo",
			"prezzo di apertura",
			"orario di apertura",
			"segnare il prezzo",
			"S/L",
			"T/P",
			"tassa di gestione",
			"Profitti e perdite fluttuanti",
			"prezzo della commissione",
			"tempo di commissione",
			"Prezzo di chiusura",
			"Orario di chiusura",
			"tempo di transazione",
			"prezzo finale",
			"Il numero di transazioni",
			"quantità",
			"profitti e perdite"
		]
	},
	"password": {
		"one": "Inserisci la password originale",
		"oneName": "vecchia password",
		"three": "Inserisci di nuovo la nuova password",
		"threeName": "inserire nuovamente la password",
		"two": "Inserisci una nuova password",
		"twoName": "nuova password"
	},
	"personLeft": {
		"bank": "Carta bancaria",
		"information": "informazione personale",
		"invite": "invita gli amici",
		"news": "notificare",
		"order": "registrazione dell'ordine",
		"recharge": "ricaricare",
		"recordIn": "registro di deposito",
		"recordOut": "Record di prelievo",
		"updatePassword": "cambiare la password",
		"verified": "Verificato",
		"wallet": "portafoglio virtuale",
		"withdraw": "ritirare"
	},
	"personTitle": {
		"country": "nazione",
		"edit": "Rivedere",
		"emailTitle": "Posta",
		"idCard": "numero di identificazione",
		"idCardFront": "ID anteriore",
		"idCardFront1": "Il retro del documento",
		"name": "Nome",
		"nameTitle": "Soprannome",
		"phoneTitle": "numero di telefono",
		"status": "non certificato",
		"status0": "certificazione",
		"status1": "verificato",
		"status2": "autenticazione rifiutata",
		"status3": "non certificato",
		"submit": "invia"
	},
	"readVer": {
		"codeOk": "Captcha è disponibile",
		"email": "Si prega di contattare il cliente per la modifica",
		"errerLog": "Errore codice di verifica",
		"idCard": "Si prega di inserire il numero ID",
		"name": "Per favore inserisci un nickname"
	},
	"register": {
		"getVerification": "ottenere il codice di verifica",
		"inpTips1": "Posta",
		"inpTips2": "parola d'ordine",
		"inpTips3": "codice di verifica",
		"inpTips4": "Codice invito",
		"login": "Login",
		"msg1": "Sono d'accordo",
		"msg2": "Termini e Condizioni",
		"msg3": "Hai già un account?",
		"reg1": "per favore inserisci la tua email",
		"reg2": "Per favore, inserisci la password",
		"reg3": "si prega di inserire il codice di verifica",
		"reg4": "Inserisci il codice di invito",
		"reg5": "Inviato con successo",
		"register": "Registra un account",
		"resetVerification": "Riacquistare"
	},
	"reset": {
		"btn": "Ripristina",
		"getSend": "ottenere il codice di verifica",
		"inpTips": "Inserisci il tuo indirizzo email",
		"msg1": "Ricordi la tua password?",
		"msg2": "accedi qui",
		"reset": "Resetta la password"
	},
	"time": {
		"15min": "15 minuti",
		"1H": "1 ora",
		"1min": "1 minuto",
		"30min": "30 minuti",
		"4H": "4 ore",
		"5day": "5 giorni",
		"5min": "5 minuti",
		"day": "Linea giornaliera",
		"moon": "Linea lunare",
		"second": "multiproprietà",
		"week": "settimanalmente"
	},
	"userWith": {
		"eightName": "Crediti",
		"fiveName": "indirizzo bancario",
		"fourName": "nome della banca",
		"nineName": "Importo del prelievo",
		"one": "Inserisci l'importo del prelievo",
		"oneName": "Trasferimento di denaro internazionale",
		"sevenName": "beneficiario",
		"sixName": "SWIFT",
		"tenFour": "Codice QR del portafoglio",
		"tenName": "Importo Ricevuta",
		"tenOne": "caricare il buono di pagamento",
		"tenThree": "indirizzo del portafoglio",
		"tenTwo": "rete di denaro",
		"threeName": "Valuta del beneficiario",
		"two": "Importo effettivo ricevuto",
		"twoName": "moneta digitale"
	},
	"wallet": {
		"add": "aggiungi portafoglio",
		"delete": "eliminare il portafoglio",
		"fourName": "Codice QR del portafoglio",
		"one": "Inserisci la rete di valute (ad es. TRC20)",
		"oneName": "tipo di valuta",
		"threeName": "indirizzo del portafoglio",
		"two": "Inserisci l'indirizzo del portafoglio",
		"twoName": "rete di denaro"
	}
}