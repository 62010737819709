export default {
	"bank": {
		"delete": "은행 카드 삭제",
		"five": "SWIF를 입력하세요.",
		"fiveName": "은행 카드 주소",
		"four": "수취인 은행 주소를 입력하세요.",
		"fourName": "은행 카드 번호",
		"one": "받는 사람의 이름을 입력하세요.",
		"oneName": "통화 유형",
		"sixName": "은행 카드 추가",
		"three": "수취계좌번호를 입력해주세요",
		"threeName": "은행 카드 이름",
		"two": "수취인 은행 이름을 입력하세요.",
		"twoName": "이름"
	},
	"common": {
		"about": "회사 소개",
		"logout": "로그아웃",
		"noData": "데이터 없음",
		"search": "찾다",
		"systemSet": "설정",
		"title": "대한민국"
	},
	"depositWith": {
		"czName": "재충전 제출",
		"eightName": "미수금",
		"fiveName": "계좌 번호",
		"fourName": "은행 이름",
		"nineName": "충전량",
		"one": "충전금액을 입력해주세요",
		"oneName": "국제 송금",
		"sevenName": "수취인",
		"sixName": "스위프트",
		"tenFour": "지갑 QR 코드",
		"tenName": "결제 금액",
		"tenOne": "결제 바우처 업로드",
		"tenThree": "지갑 주소",
		"tenTwo": "돈 네트워크",
		"threeName": "통화 유형",
		"two": "실제 결제 금액",
		"twoName": "디지털 통화",
		"txName": "철회 제출"
	},
	"information": {
		"email": "수정을 위해 고객에게 연락하십시오",
		"name": "닉네임을 입력하세요.",
		"phone": "전화번호를 입력하세요."
	},
	"inOutMoney": {
		"fiveName": "금액",
		"fourName": "실패하다",
		"oneName": "모두",
		"sevenName": "시간",
		"sixName": "상태",
		"threeName": "성공",
		"twoName": "검토할"
	},
	"login": {
		"demo": "데모 계정",
		"forget": "비밀번호를 잊어버리다",
		"inpTips1": "이메일 주소",
		"inpTips2": "비밀번호",
		"login": "로그인",
		"reg1": "이메일을 입력해주세요",
		"reg2": "비밀번호를 입력 해주세요",
		"register": "등록하다",
		"remember": "암호 기억",
		"tipMsg": "계정 없음"
	},
	"marketPairs": {
		"collection": "내 컬렉션",
		"pairs": "상품 코드",
		"purchasePrice": "구매 가격",
		"sellPrice": "판매 가격",
		"tradeType": "다양한 거래"
	},
	"marketTrade": {
		"balance": "균형:",
		"bookOrder": "주문",
		"buy": "Buy",
		"buying": "사다",
		"currentRate": "현재 레버리지:",
		"limit": "위탁 주문",
		"margin": "여유:",
		"marginAvailable": "사용 가능한 마진:",
		"marginRatio": "증거금 비율:",
		"market": "시장 주문",
		"price": "가격",
		"quantity": "수량",
		"sell": "Sell",
		"selling": "구매 또는 하락",
		"stopLoss": "손절매",
		"takeProfit": "이익을 얻다",
		"volume": "순자산:"
	},
	"notification": {
		"msg1": "성공적으로 수정됨",
		"msg10": "지갑을 추가해주세요",
		"msg11": "결제수단을 바꿔주세요",
		"msg12": "고쳐 쓰다",
		"msg13": "은행 카드를 추가하세요.",
		"msg14": "재설정 성공",
		"msg15": "개정하다",
		"msg16": "삭제",
		"msg17": "포지션을 청산하다",
		"msg18": "성공적인 작업",
		"msg19": "손절매 가격은 시가보다 커야 합니다.",
		"msg2": "성공적으로 제출됨",
		"msg20": "이익 실현 가격은 시작 가격보다 낮아야 합니다.",
		"msg21": "주문 가격은 비워둘 수 없습니다.",
		"msg22": "성공적인 작업",
		"msg23": "인증 코드 전송됨",
		"msg3": "성공적으로 추가되었습니다",
		"msg4": "등록 성공",
		"msg5": "성공적으로 삭제",
		"msg6": "성공적으로 업로드",
		"msg7": "확신하는",
		"msg8": "취소",
		"msg9": "지갑을 삭제하시겠습니까?"
	},
	"orderHistory": {
		"cancel": "해제",
		"close": "포지션을 청산하다",
		"closedOrders": "클로징 오더",
		"closing": "폐쇄",
		"commissionedOrder": "위탁 주문",
		"endTime": "종료 날짜",
		"openOrders": "오픈 포지션 주문",
		"operate": "작동하다",
		"orderHistory": "역사적 순서",
		"startTime": "시작일",
		"thead": [
			"상품 코드",
			"홀수",
			"유형",
			"시가",
			"개장 시간",
			"평균 가격",
			"S/L",
			"T/P",
			"수수료",
			"부동 손익",
			"수수료 가격",
			"커미션 시간",
			"종가",
			"마감시간",
			"거래 시간",
			"최종 가격",
			"거래 건수",
			"수량",
			"이익과 손실"
		]
	},
	"password": {
		"one": "원래 비밀번호를 입력해주세요",
		"oneName": "기존 비밀번호",
		"three": "새로운 비밀번호를 다시 입력해주세요",
		"threeName": "비밀번호를 다시 입력하세요",
		"two": "새 비밀번호를 입력하세요",
		"twoName": "새 비밀번호"
	},
	"personLeft": {
		"bank": "은행 카드",
		"information": "개인 정보",
		"invite": "친구를 초대",
		"news": "알리다",
		"order": "주문 기록",
		"recharge": "재충전",
		"recordIn": "예금 기록",
		"recordOut": "출금기록",
		"updatePassword": "비밀번호 변경",
		"verified": "검증됨",
		"wallet": "가상 지갑",
		"withdraw": "철회하다"
	},
	"personTitle": {
		"country": "국가",
		"edit": "개정하다",
		"emailTitle": "우편",
		"idCard": "ID 번호",
		"idCardFront": "신분증 앞면",
		"idCardFront1": "문서 뒷면",
		"name": "이름",
		"nameTitle": "닉네임",
		"phoneTitle": "전화 번호",
		"status": "인증되지 않음",
		"status0": "인증",
		"status1": "확인",
		"status2": "인증 거부됨",
		"status3": "인증되지 않음",
		"submit": "제출하다"
	},
	"readVer": {
		"codeOk": "보안 문자 사용 가능",
		"email": "수정을 위해 고객에게 연락하십시오",
		"errerLog": "인증 코드 오류",
		"idCard": "주민등록번호를 입력해주세요",
		"name": "닉네임을 입력하세요."
	},
	"register": {
		"getVerification": "확인 코드 받기",
		"inpTips1": "우편",
		"inpTips2": "비밀번호",
		"inpTips3": "확인 코드",
		"inpTips4": "초대 코드",
		"login": "로그인",
		"msg1": "나는 동의한다",
		"msg2": "이용약관",
		"msg3": "이미 계정이 있습니까?",
		"reg1": "이메일을 입력해주세요",
		"reg2": "비밀번호를 입력 해주세요",
		"reg3": "인증 코드를 입력하세요",
		"reg4": "초대코드를 입력해주세요",
		"reg5": "성공적으로 전송",
		"register": "계정 등록",
		"resetVerification": "재획득"
	},
	"reset": {
		"btn": "초기화",
		"getSend": "확인 코드 받기",
		"inpTips": "당신의 이메일 주소를 입력하십시오",
		"msg1": "비밀번호를 기억하십니까?",
		"msg2": "여기에 로그인",
		"reset": "암호를 재설정"
	},
	"time": {
		"15min": "15 분",
		"1H": "1 시간",
		"1min": "1 분",
		"30min": "30 분",
		"4H": "4 시간",
		"5day": "5 일",
		"5min": "5 분",
		"day": "데일리라인",
		"moon": "문 라인",
		"second": "시분할",
		"week": "주간"
	},
	"userWith": {
		"eightName": "미수금",
		"fiveName": "계좌 번호",
		"fourName": "은행 이름",
		"nineName": "출금 금액",
		"one": "출금금액을 입력해주세요",
		"oneName": "국제 송금",
		"sevenName": "수취인",
		"sixName": "SWIFT",
		"tenFour": "지갑 QR 코드",
		"tenName": "영수증 금액",
		"tenOne": "결제 바우처 업로드",
		"tenThree": "지갑 주소",
		"tenTwo": "돈 네트워크",
		"threeName": "수취 통화",
		"two": "실제 받은 금액",
		"twoName": "디지털 화폐"
	},
	"wallet": {
		"add": "지갑 추가",
		"delete": "지갑 삭제",
		"fourName": "지갑 QR 코드",
		"one": "통화 네트워크(예: TRC20)를 입력하세요.",
		"oneName": "통화 유형",
		"threeName": "지갑 주소",
		"two": "지갑주소를 입력해주세요",
		"twoName": "돈 네트워크"
	}
}