import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
const Exchange =()=>  import("../views/Exchange.vue");
const Login =()=>  import("../views/Login.vue");
const Notfound =()=>  import("../views/Notfound.vue");
const Reset =()=>  import("../views/Reset.vue");
const SignUp =()=>  import("../views/Signup.vue");
const TermsAndConditions =()=>  import("../views/TermsAndConditions.vue");
const home =()=>  import("../views/index.vue");

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/sign-up",
    name: "Signup",
    component: SignUp,
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: '/home',
    name: "home",
    component: home,
    children: [
      {
        path: "/exchange",
        name: "Exchange",
        component: Exchange,
      },
      
      {
        path: "/404",
        name: "Notfound",
        component: Notfound,
      }
    ]
  },
  // {
  //   path: '*',
  //   name: '404',
  //   component: Wallet
  // }
  
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
