const theme = {
  state: {
    theme: true
  },
  mutations: {
    SET_THEME(state, theme) {
      if (!theme) {
        state.theme = false;
        document.body.classList.remove("dark");
      } else {
        state.theme = true;
        document.body.classList.add("dark");
      }
    },
  },
  actions: {
    set_theme({ commit }, data) {
      commit("SET_THEME", data);
    }
  }
};

export default theme;
