export default {
    "common": {
        "title": "繁體中文",
        "noData": "没有数据",
        "systemSet": "設置",
        "logout": "退出登錄",
        "about": "關於我們",
        "search": "搜索",
    },
    "login": {
        "login": "登錄",
        "forget": "忘記密碼",
        "remember": "記住密碼",
        "tipMsg": "沒有賬號",
        "register": "註冊",
        "inpTips1": "郵箱地址",
        "inpTips2": "密碼",
        "reg1": "請輸入郵箱",
        "reg2": "請輸入密碼",
        "demo": "模擬賬戶"
    },
    "register": {
        "register": "註冊賬號",
        "msg1": "我同意",
        "msg2": "條款和條件",
        "msg3": "已有帳戶？",
        "login": "登錄",
        "inpTips1": "郵箱",
        "inpTips2": "密码",
        "inpTips3": "驗證碼",
        "inpTips4": "邀請碼",
        "getVerification": "獲取驗證碼",
        "resetVerification": "重新獲取",
        "reg1": "請輸入郵箱",
        "reg2": "請輸入密碼",
        "reg3": "請輸入驗證碼",
        "reg4": "請輸入邀請碼",
        "reg5": "發送成功",
    },
    "reset": {
        "reset": "重置密碼",
        "btn": "重置",
        "msg1": "記住密碼？",
        "msg2": "在這裡登錄",
        "inpTips": "請輸入你的郵箱地址",
        "getSend": "獲取驗證碼",
    },
    "marketPairs": {
        "tradeType": "交易品種",
        "collection": "我的收藏",
        "pairs": "商品代碼",
        "sellPrice": "賣價",
        "purchasePrice": "買價",
    },
    "marketTrade": {
        "market": "市價訂單",
        "limit": "委託訂單",
        "balance": "結餘：",
        "volume": "淨值：",
        "margin": "保證金：",
        "marginRatio": "保證金比例：",
        "marginAvailable": "可用保證金：",
        "currentRate": "當前杠桿：",
        "buy": "買漲",
        "sell": "買跌",
        "buying": "買漲中",
        "selling": "買跌中",
        "quantity": "數量",
        "price": "價格",
        "stopLoss": "止損",
        "takeProfit": "止盈",
        "bookOrder": "下单",
    },
    "orderHistory": {
        "openOrders": "持倉訂單",
        "closedOrders": "成交訂單",
        "orderHistory": "歷史訂單",
        "commissionedOrder": "委託訂單",
        "close": "平倉",
        "closing": "平倉中",
        "startTime": "開始日期",
        "endTime": "結束日期",
        "operate":"操作",
        "cancel": "撤單",
        "thead": [
            "商品代碼",
            "單號",
            "類型",
            "開倉價",
            "開倉時間",
            "標記價",
            "S/L",
            "T/P",
            "手續費",
            "浮動盈虧",
            "委託價",
            "委託時間",
            "平倉價",
            "平倉時間",
            "成交時間",
            "成交價",
            "成交數量",
            "數量",
            "盈虧"
        ]
    },
    "personLeft":{
        "information":"個人資料",
        "verified":"實名認證",
        "updatePassword":"更改密碼",
        "wallet":"虛擬錢包",
        "bank":"銀行卡",
        "recharge":"充值",
        "withdraw":"提現",
        "recordIn":"入金記錄",
        "recordOut":"出金記錄",
        "news":"通知",
        "order":"訂單記錄",
        "invite": "邀請好友"
    },
    "personTitle":{
        "nameTitle":"暱稱",
        "phoneTitle":"手機號碼",
        "country": "國家",
        "emailTitle":"郵箱",
        "name":"姓名",
        "idCard":"證件號碼",
        "idCardFront":"證件正面",
        "idCardFront1":"證件反面",
        "status":"未認證",
        "status0":"認證中",
        "status1":"已認證",
        "status2":"認證拒絕",
        "status3":"未認證",
        "edit":"修改",
        "submit":"提交",
    },
    "information":{
        "name":"請輸入暱稱",
        "phone":"請輸入手機號碼",
        "email":"需求修改請聯繫客戶",
    },
    "readVer":{
        "name":"請輸入暱稱",
        "idCard":"請輸入身份證號碼",
        "email":"需求修改請聯繫客戶",
        "errerLog": "驗證碼錯誤",
        "codeOk": "驗證碼可用",
    },
    "password":{
        "one":"請輸入原密碼",
        "oneName":"原密碼",
        "two":"請輸入新密碼",
        "twoName":"新的密碼",
        "three":"請再次輸入新密碼",
        "threeName":"再次輸入密碼",
    },
    "wallet":{
        "add": "添加錢包",
        "delete": "刪除錢包",
        "one":"請輸入貨幣網絡（例TRC20）",
        "two":"請輸入錢包地址",
        "oneName":"貨幣類型",
        "twoName":"貨幣網絡",
        "threeName":"錢包地址",
        "fourName":"錢包二維碼",
    },
    "bank":{
        "one":"請輸入收款姓名",
        "two":"請輸入收款銀行名稱",
        "three":"請輸入收款賬號",
        "four":"請輸入收款銀行地址",
        "five":"請輸入SWIF",
        "oneName":"貨幣類型",
        "twoName":"姓名",
        "threeName":"銀行卡名稱",
        "fourName":"銀行卡卡號",
        "fiveName":"銀行卡地址",
        "sixName":"添加銀行卡",
        "delete":"刪除銀行卡"
    },
    "depositWith":{
        "one":"請輸入充值金額",
        "two":"實際支付金額",
        "oneName":"國際匯款",
        "twoName":"數字貨幣",
        "threeName":"貨幣類型",
        "fourName":"銀行名稱",
        "fiveName":"銀行地址",
        "sixName":"SWITF",
        "sevenName":"收款人",
        "eightName":"收款賬戶",
        "nineName":"充值金額",
        "tenName":"支付金額",
        "tenOne":"上傳支付憑證",
        "tenTwo":"貨幣網絡",
        "tenThree":"錢包地址",
        "tenFour":"錢包二維碼",
        "czName": "提交充值",
        "txName": "提交提現"
    },
    "userWith":{
        "one":"請輸入提現金額",
        "two":"實際到賬金額",
        "oneName":"國際匯款",
        "twoName":"數字貨幣",
        "threeName":"收款人貨幣",
        "fourName":"銀行名稱",
        "fiveName":"銀行地址",
        "sixName":"SWITF",
        "sevenName":"收款人",
        "eightName":"收款賬戶",
        "nineName":"提現金額",
        "tenName":"到賬金額",
        "tenOne":"上傳支付憑證",
        "tenTwo":"貨幣網絡",
        "tenThree":"錢包地址",
        "tenFour":"錢包二維碼",
    },
    "inOutMoney":{
        "oneName":"全部",
        "twoName":"待審核",
        "threeName":"成功",
        "fourName":"失敗",
        "fiveName":"金額",
        "sixName":"狀態",
        "sevenName":"時間",
    },
    "notification": {
        "msg1": "修改成功",
        "msg2": "提交成功",
        "msg3": "添加成功",
        "msg4": "註冊成功",
        "msg5": "刪除成功",
        "msg6": "上傳成功",
        "msg7": "確定",
        "msg8": "取消",
        "msg9": "是否確認刪除該錢包",
        "msg10": "請添加錢包",
        "msg13": "請添加銀行卡",
        "msg11": "請切換支付方式",
        "msg12": "更新",
        "msg14": "重置成功",
        "msg15": "修改",
        "msg16": "刪除",
        "msg17": "平倉",
        "msg18": "操作成功",
        "msg19": "止損價必須大於開倉價",
        "msg20": "止盈價必須小於開倉價",
        "msg21": "委託價不能為空",
        "msg22": "操作成功",
        "msg23": "驗證碼已發送"
    },
    "time": {
        "second": "分时",
        "1min":"1分钟",
        "5min":"5分钟",
        "15min": "15分钟",
        "30min": "30分钟",
        "1H": "1小时",
        "4H": "4小时",
        "day": "日线",
        "5day":"5日",
        "week": "周线",
        "moon": "月线",
    }
}
