export default {
	"bank": {
		"delete": "padamkan kad bank",
		"five": "Sila masukkan SWIF",
		"fiveName": "alamat kad bank",
		"four": "Sila masukkan alamat bank penerima",
		"fourName": "Nombor kad bank",
		"one": "Sila masukkan nama penerima",
		"oneName": "jenis mata wang",
		"sixName": "tambah kad bank",
		"three": "Sila masukkan nombor akaun penerima",
		"threeName": "Nama kad bank",
		"two": "Sila masukkan nama bank benefisiari",
		"twoName": "Nama"
	},
	"common": {
		"about": "tentang kita",
		"logout": "log keluar",
		"noData": "tiada data",
		"search": "cari",
		"systemSet": "sediakan",
		"title": "Malaysia"
	},
	"depositWith": {
		"czName": "mengemukakan caj semula",
		"eightName": "Akaun belum terima",
		"fiveName": "alamat bank",
		"fourName": "nama bank",
		"nineName": "Jumlah cas semula",
		"one": "Sila masukkan jumlah caj semula",
		"oneName": "Pemindahan Wang Antarabangsa",
		"sevenName": "penerima bayaran",
		"sixName": "SWITF",
		"tenFour": "Kod QR dompet",
		"tenName": "jumlah pembayaran",
		"tenOne": "muat naik baucar bayaran",
		"tenThree": "alamat dompet",
		"tenTwo": "rangkaian wang",
		"threeName": "jenis mata wang",
		"two": "Jumlah bayaran sebenar",
		"twoName": "mata wang digital",
		"txName": "mengemukakan pengeluaran"
	},
	"information": {
		"email": "Sila hubungi pelanggan untuk pengubahsuaian",
		"name": "Sila masukkan nama panggilan",
		"phone": "Sila masukkan nombor telefon"
	},
	"inOutMoney": {
		"fiveName": "jumlah",
		"fourName": "gagal",
		"oneName": "semua",
		"sevenName": "masa",
		"sixName": "negeri",
		"threeName": "kejayaan",
		"twoName": "untuk disemak"
	},
	"login": {
		"demo": "akaun demo",
		"forget": "lupa kata laluan",
		"inpTips1": "alamat emel",
		"inpTips2": "kata laluan",
		"login": "Log masuk",
		"reg1": "sila masukkan email anda",
		"reg2": "sila masukkan kata laluan",
		"register": "mendaftar",
		"remember": "ingat kata laluan",
		"tipMsg": "Tiada akaun"
	},
	"marketPairs": {
		"collection": "koleksi saya",
		"pairs": "kod komoditi",
		"purchasePrice": "harga pembelian",
		"sellPrice": "harga jualan",
		"tradeType": "pelbagai perdagangan"
	},
	"marketTrade": {
		"balance": "seimbang:",
		"bookOrder": "membuat pesanan",
		"buy": "Buy",
		"buying": "Membeli",
		"currentRate": "Leverage semasa:",
		"limit": "pesanan ditauliahkan",
		"margin": "Margin:",
		"marginAvailable": "Margin Tersedia:",
		"marginRatio": "Nisbah margin:",
		"market": "pesanan pasaran",
		"price": "harga",
		"quantity": "kuantiti",
		"sell": "Sell",
		"selling": "Membeli atau jatuh",
		"stopLoss": "hentikan kerugian",
		"takeProfit": "mengambil keuntungan",
		"volume": "nilai bersih:"
	},
	"notification": {
		"msg1": "Berjaya diubah suai",
		"msg10": "Sila tambah dompet",
		"msg11": "Sila tukar kaedah pembayaran",
		"msg12": "memperbaharui",
		"msg13": "Sila tambah kad bank",
		"msg14": "set semula berjaya",
		"msg15": "Semak semula",
		"msg16": "padam",
		"msg17": "menutup kedudukan",
		"msg18": "Operasi yang berjaya",
		"msg19": "Harga stop loss mestilah lebih besar daripada harga pembukaan",
		"msg2": "Dihantar dengan jayanya",
		"msg20": "Harga ambil untung mestilah kurang daripada harga pembukaan",
		"msg21": "Harga pesanan tidak boleh kosong",
		"msg22": "Operasi yang berjaya",
		"msg23": "Kod pengesahan dihantar",
		"msg3": "Berjaya ditambahkan",
		"msg4": "pendaftaran berjaya",
		"msg5": "berjaya dipadamkan",
		"msg6": "berjaya dimuat naik",
		"msg7": "pasti",
		"msg8": "Batal",
		"msg9": "Adakah anda pasti akan memadamkan dompet itu"
	},
	"orderHistory": {
		"cancel": "Pembatalan",
		"close": "menutup kedudukan",
		"closedOrders": "Pesanan penutup",
		"closing": "Penutup",
		"commissionedOrder": "pesanan ditauliahkan",
		"endTime": "tarikh tamat",
		"openOrders": "perintah kedudukan terbuka",
		"operate": "beroperasi",
		"orderHistory": "Susunan sejarah",
		"startTime": "tarikh mula",
		"thead": [
			"kod komoditi",
			"nombor ganjil",
			"menaip",
			"harga pembukaan",
			"waktu buka",
			"tandakan harga",
			"S/L",
			"T/P",
			"yuran pengendalian",
			"Untung dan rugi terapung",
			"harga komisen",
			"masa komisen",
			"Harga penutup",
			"Waktu tutup",
			"masa transaksi",
			"harga akhir",
			"Bilangan transaksi",
			"kuantiti",
			"untung dan rugi"
		]
	},
	"password": {
		"one": "Sila masukkan kata laluan asal",
		"oneName": "kata laluan lama",
		"three": "Sila masukkan kata laluan baharu sekali lagi",
		"threeName": "masukkan kata laluan sekali lagi",
		"two": "Sila masukkan kata laluan baharu",
		"twoName": "kata laluan baharu"
	},
	"personLeft": {
		"bank": "kad bank",
		"information": "maklumat peribadi",
		"invite": "menjemput kawan-kawan",
		"news": "maklumkan",
		"order": "rekod pesanan",
		"recharge": "cas semula",
		"recordIn": "rekod deposit",
		"recordOut": "Rekod pengeluaran",
		"updatePassword": "tukar kata laluan",
		"verified": "Disahkan",
		"wallet": "dompet maya",
		"withdraw": "menarik diri"
	},
	"personTitle": {
		"country": "bangsa",
		"edit": "Semak semula",
		"emailTitle": "Mel",
		"idCard": "nombor ID",
		"idCardFront": "ID depan",
		"idCardFront1": "Bahagian belakang dokumen",
		"name": "Nama",
		"nameTitle": "nama panggilan",
		"phoneTitle": "nombor telefon",
		"status": "tidak diperakui",
		"status0": "pensijilan",
		"status1": "disahkan",
		"status2": "pengesahan ditolak",
		"status3": "tidak diperakui",
		"submit": "serahkan"
	},
	"readVer": {
		"codeOk": "Captcha tersedia",
		"email": "Sila hubungi pelanggan untuk pengubahsuaian",
		"errerLog": "Ralat kod pengesahan",
		"idCard": "Sila masukkan nombor ID",
		"name": "Sila masukkan nama panggilan"
	},
	"register": {
		"getVerification": "dapatkan kod pengesahan",
		"inpTips1": "Mel",
		"inpTips2": "kata laluan",
		"inpTips3": "kod pengesahan",
		"inpTips4": "Kod Jemputan",
		"login": "Log masuk",
		"msg1": "saya setuju",
		"msg2": "terma dan syarat",
		"msg3": "Sudah mempunyai akaun?",
		"reg1": "sila masukkan email anda",
		"reg2": "sila masukkan kata laluan",
		"reg3": "sila masukkan kod pengesahan",
		"reg4": "Sila masukkan kod jemputan",
		"reg5": "Berjaya dihantar",
		"register": "Daftar akaun",
		"resetVerification": "Perolehi semula"
	},
	"reset": {
		"btn": "set semula",
		"getSend": "dapatkan kod pengesahan",
		"inpTips": "Sila masukkan alamat e-mel anda",
		"msg1": "Ingat kata laluan anda?",
		"msg2": "daftar masuk disini",
		"reset": "menetapkan semula kata laluan"
	},
	"time": {
		"15min": "15 minit",
		"1H": "1 jam",
		"1min": "1 minit",
		"30min": "30 minit",
		"4H": "4 jam",
		"5day": "5 hari",
		"5min": "5 minit",
		"day": "Talian harian",
		"moon": "Garis bulan",
		"second": "perkongsian masa",
		"week": "Setiap minggu"
	},
	"userWith": {
		"eightName": "Akaun belum terima",
		"fiveName": "alamat bank",
		"fourName": "nama bank",
		"nineName": "Jumlah Pengeluaran",
		"one": "Sila masukkan jumlah pengeluaran",
		"oneName": "Pemindahan Wang Antarabangsa",
		"sevenName": "penerima bayaran",
		"sixName": "SWIFT",
		"tenFour": "Kod QR dompet",
		"tenName": "Jumlah resit",
		"tenOne": "muat naik baucar bayaran",
		"tenThree": "alamat dompet",
		"tenTwo": "rangkaian wang",
		"threeName": "Mata Wang Benefisiari",
		"two": "Jumlah sebenar diterima",
		"twoName": "mata wang digital"
	},
	"wallet": {
		"add": "tambah dompet",
		"delete": "padam dompet",
		"fourName": "Kod QR dompet",
		"one": "Sila masukkan rangkaian mata wang (cth TRC20)",
		"oneName": "jenis mata wang",
		"threeName": "alamat dompet",
		"two": "Sila masukkan alamat dompet",
		"twoName": "rangkaian wang"
	}
}