export default {
	"bank": {
		"delete": "Bankkarte löschen",
		"five": "Bitte geben Sie SWIF ein",
		"fiveName": "Bankkartenadresse",
		"four": "Bitte geben Sie die Bankadresse des Empfängers ein",
		"fourName": "Bankkartennummer",
		"one": "Bitte geben Sie den Namen des Empfängers ein",
		"oneName": "Währungstyp",
		"sixName": "Bankkarte hinzufügen",
		"three": "Bitte geben Sie die empfangende Kontonummer ein",
		"threeName": "Bankkartenname",
		"two": "Bitte geben Sie den Namen der Empfängerbank ein",
		"twoName": "Name"
	},
	"common": {
		"about": "über uns",
		"logout": "austragen",
		"noData": "keine Daten",
		"search": "suchen",
		"systemSet": "aufstellen",
		"title": "Deutsch"
	},
	"depositWith": {
		"czName": "Aufladung einreichen",
		"eightName": "Forderungen",
		"fiveName": "Bankadresse",
		"fourName": "Bank Name",
		"nineName": "Aufladebetrag",
		"one": "Bitte geben Sie den Aufladebetrag ein",
		"oneName": "Internationaler Geldtransfer",
		"sevenName": "Zahlungsempfänger",
		"sixName": "SWITF",
		"tenFour": "Wallet-QR-Code",
		"tenName": "Zahlungsbetrag",
		"tenOne": "Zahlungsbeleg hochladen",
		"tenThree": "Wallet-Adresse",
		"tenTwo": "Geld Netzwerk",
		"threeName": "Währungstyp",
		"two": "Tatsächlicher Zahlungsbetrag",
		"twoName": "digitale Währung",
		"txName": "Rücktritt einreichen"
	},
	"information": {
		"email": "Bitte kontaktieren Sie den Kunden für Änderungen",
		"name": "Bitte geben Sie einen Spitznamen ein",
		"phone": "Bitte geben Sie die Telefonnummer ein"
	},
	"inOutMoney": {
		"fiveName": "die Summe",
		"fourName": "scheitern",
		"oneName": "alle",
		"sevenName": "Zeit",
		"sixName": "Zustand",
		"threeName": "Erfolg",
		"twoName": "überprüft werden"
	},
	"login": {
		"demo": "Demokonto",
		"forget": "Passwort vergessen",
		"inpTips1": "E-Mail-Addresse",
		"inpTips2": "Passwort",
		"login": "Anmeldung",
		"reg1": "Bitte geben Sie Ihre E-Mail ein",
		"reg2": "Bitte Passwort eingeben",
		"register": "registrieren",
		"remember": "Passwort merken",
		"tipMsg": "Kein Account"
	},
	"marketPairs": {
		"collection": "meine Sammlung",
		"pairs": "Warencode",
		"purchasePrice": "Kaufpreis",
		"sellPrice": "Verkaufspreis",
		"tradeType": "Handelsvielfalt"
	},
	"marketTrade": {
		"balance": "Gleichgewicht:",
		"bookOrder": "eine Bestellung aufgeben",
		"buy": "Buy",
		"buying": "Aufkaufen",
		"currentRate": "Aktueller Hebel:",
		"limit": "Auftragsauftrag",
		"margin": "Rand:",
		"marginAvailable": "Verfügbare Spanne:",
		"marginRatio": "Margenverhältnis:",
		"market": "Marktordnung",
		"price": "Preis",
		"quantity": "Menge",
		"sell": "Sell",
		"selling": "Kaufen oder Fallen",
		"stopLoss": "Verlust stoppen",
		"takeProfit": "Gewinn mitnehmen",
		"volume": "Reinvermögen:"
	},
	"notification": {
		"msg1": "Erfolgreich geändert",
		"msg10": "Bitte fügen Sie eine Brieftasche hinzu",
		"msg11": "Bitte wechseln Sie die Zahlungsmethode",
		"msg12": "erneuern",
		"msg13": "Bitte fügen Sie eine Bankkarte hinzu",
		"msg14": "Zurücksetzen erfolgreich",
		"msg15": "Überarbeiten",
		"msg16": "löschen",
		"msg17": "eine Position schließen",
		"msg18": "Erfolgreiche Operation",
		"msg19": "Der Stop-Loss-Kurs muss größer sein als der Eröffnungskurs",
		"msg2": "erfolgreich eingereicht",
		"msg20": "Der Take-Profit-Preis muss unter dem Eröffnungspreis liegen",
		"msg21": "Der Bestellpreis darf nicht leer sein",
		"msg22": "Erfolgreiche Operation",
		"msg23": "Bestätigungscode gesendet",
		"msg3": "Erfolgreich hinzugefügt",
		"msg4": "Registrierung erfolgreich",
		"msg5": "erfolgreich gelöscht",
		"msg6": "erfolgreich hochgeladen",
		"msg7": "Sicher",
		"msg8": "Stornieren",
		"msg9": "Möchten Sie die Brieftasche wirklich löschen?"
	},
	"orderHistory": {
		"cancel": "Stornierung",
		"close": "eine Position schließen",
		"closedOrders": "Abschlussreihenfolge",
		"closing": "Schließen",
		"commissionedOrder": "Auftragsauftrag",
		"endTime": "Endtermin",
		"openOrders": "Positionsauftrag öffnen",
		"operate": "arbeiten",
		"orderHistory": "Historische Ordnung",
		"startTime": "Startdatum",
		"thead": [
			"Warencode",
			"ungerade Zahl",
			"Typ",
			"Eröffnungskurs",
			"Öffnungszeit",
			"Preis markieren",
			"S/L",
			"T/P",
			"Bearbeitungsgebühr",
			"Gleitender Gewinn und Verlust",
			"Provisionspreis",
			"Kommissionszeit",
			"Schlusskurs",
			"Geschäftsschluss",
			"Transaktionszeit",
			"Endgültiger Preis",
			"Die Anzahl der Transaktionen",
			"Menge",
			"Gewinn-und Verlust"
		]
	},
	"password": {
		"one": "Bitte geben Sie das ursprüngliche Passwort ein",
		"oneName": "Altes Passwort",
		"three": "Bitte neues Passwort erneut eingeben",
		"threeName": "Passwort erneut eingeben",
		"two": "Bitte geben Sie ein neues Passwort ein",
		"twoName": "Neues Kennwort"
	},
	"personLeft": {
		"bank": "Bankkarte",
		"information": "Persönliche Angaben",
		"invite": "Freunde einladen",
		"news": "benachrichtigen",
		"order": "Bestellprotokoll",
		"recharge": "aufladen",
		"recordIn": "Hinterlegungsnachweis",
		"recordOut": "Entzugsprotokoll",
		"updatePassword": "Kennwort ändern",
		"verified": "Verifiziert",
		"wallet": "virtuelle Brieftasche",
		"withdraw": "zurückziehen"
	},
	"personTitle": {
		"country": "Nation",
		"edit": "Überarbeiten",
		"emailTitle": "Post",
		"idCard": "ID-Nummer",
		"idCardFront": "Ausweis vorne",
		"idCardFront1": "Die Rückseite des Dokuments",
		"name": "Name",
		"nameTitle": "Spitzname",
		"phoneTitle": "Telefonnummer",
		"status": "nicht zertifiziert",
		"status0": "Zertifizierung",
		"status1": "verifiziert",
		"status2": "Authentifizierung abgelehnt",
		"status3": "nicht zertifiziert",
		"submit": "einreichen"
	},
	"readVer": {
		"codeOk": "Captcha ist verfügbar",
		"email": "Bitte kontaktieren Sie den Kunden für Änderungen",
		"errerLog": "Bestätigungscode-Fehler",
		"idCard": "Bitte ID-Nummer eingeben",
		"name": "Bitte geben Sie einen Spitznamen ein"
	},
	"register": {
		"getVerification": "Bestätigungscode erhalten",
		"inpTips1": "Post",
		"inpTips2": "Passwort",
		"inpTips3": "Bestätigungs-Code",
		"inpTips4": "Einladungscode",
		"login": "Anmeldung",
		"msg1": "Ich stimme zu",
		"msg2": "Geschäftsbedingungen",
		"msg3": "Sie haben bereits ein Konto?",
		"reg1": "Bitte geben Sie Ihre E-Mail ein",
		"reg2": "Bitte Passwort eingeben",
		"reg3": "Bitte geben Sie den Bestätigungscode ein",
		"reg4": "Bitte geben Sie den Einladungscode ein",
		"reg5": "Erfolgreich gesendet",
		"register": "Einen Account registrieren",
		"resetVerification": "Wiedererlangen"
	},
	"reset": {
		"btn": "zurücksetzen",
		"getSend": "Bestätigungscode erhalten",
		"inpTips": "Geben Sie bitte Ihre Email-Adresse ein",
		"msg1": "Erinnern Sie sich an Ihr Passwort?",
		"msg2": "Hier anmelden",
		"reset": "Passwort zurücksetzen"
	},
	"time": {
		"15min": "15 Minuten",
		"1H": "1 Stunde",
		"1min": "1 Minute",
		"30min": "30 Minuten",
		"4H": "4 Stunden",
		"5day": "5 Tage",
		"5min": "5 Minuten",
		"day": "Tägliche Linie",
		"moon": "Mondlinie",
		"second": "Time-Sharing",
		"week": "Wöchentlich"
	},
	"userWith": {
		"eightName": "Forderungen",
		"fiveName": "Bankadresse",
		"fourName": "Bank Name",
		"nineName": "Auszahlungsbetrag",
		"one": "Bitte geben Sie den Auszahlungsbetrag ein",
		"oneName": "Internationaler Geldtransfer",
		"sevenName": "Zahlungsempfänger",
		"sixName": "SWIFT",
		"tenFour": "Wallet-QR-Code",
		"tenName": "Rechnungsbetrag",
		"tenOne": "Zahlungsbeleg hochladen",
		"tenThree": "Wallet-Adresse",
		"tenTwo": "Geld Netzwerk",
		"threeName": "Währung des Begünstigten",
		"two": "Tatsächlich erhaltener Betrag",
		"twoName": "digitale Währung"
	},
	"wallet": {
		"add": "Geldbörse hinzufügen",
		"delete": "Geldbörse löschen",
		"fourName": "Wallet-QR-Code",
		"one": "Bitte geben Sie das Währungsnetzwerk ein (zB TRC20)",
		"oneName": "Währungstyp",
		"threeName": "Wallet-Adresse",
		"two": "Bitte geben Sie die Wallet-Adresse ein",
		"twoName": "Geld Netzwerk"
	}
}