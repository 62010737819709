export default  {
	"bank": {
		"five": "Key in SWIFT Code",
		"fiveName": "Banking Card Address",
		"four": "Key in Receiving Bank's Address",
		"fourName": "Banking Card Number",
		"one": "Key in Receiver's Name",
		"oneName": "Currency Type",
		"three": "Key in Receiver's Account Number",
		"threeName": "Banking Card Name",
		"two": "Key in Receiving Bank's Name",
		"twoName": "Name",
		"sixName":"add bank account",
        "delete":"delete bank account"
	},
	"common": {
		"about": "About",
		"logout": "Log Out",
		"noData": "No Data",
		"search": "Search",
		"systemSet": "System Set",
		"title": "English"
	},
	"depositWith": {
		"eightName": "Receiver's Account",
		"fiveName": "Bank Address",
		"fourName": "Bank Name",
		"nineName": "Deposit Amount",
		"one": "Key in Deposit Amount",
		"oneName": "International Transaction",
		"sevenName": "Receiver's Name",
		"sixName": "SWIFT",
		"tenFour": "Wallet Barcode",
		"tenName": "Exact Amount Payable",
		"tenOne": "Upload Proof of Payment",
		"tenThree": "Wallet Address",
		"tenTwo": "Currency Type",
		"threeName": "Currency Type",
		"two": "Exact Amount Payable",
		"twoName": "Cryptocurrency",
		"czName": "recharge",
		"txName": "withdrawal"
	},
	"information": {
		"email": "To Change Contact Infomation, Please Contact Customer Service",
		"name": "Key in Username",
		"phone": "Key in Phone Number"
	},
	"inOutMoney": {
		"fiveName": "Amount",
		"fourName": "Failed",
		"oneName": "All",
		"sevenName": "Time",
		"sixName": "Status",
		"threeName": "Success",
		"twoName": "Verification Pending"
	},
	"login": {
		"forget": "Forget Password",
		"inpTips1": "Email Address",
		"inpTips2": "Password",
		"login": "Log In",
		"reg1": "Key in Email Address",
		"reg2": "Key in Password",
		"register": "Register",
		"remember": "Remember Password",
		"tipMsg": "Account Not Found",
		"demo": "Demo Account",
	},
	"marketPairs": {
		"collection": "My Collection",
		"pairs": "Pair Code",
		"purchasePrice": "Buying Price",
		"sellPrice": "Selling Price",
		"tradeType": "Trading Type"
	},
	"marketTrade": {
		"balance": "Balance: ",
		"bookOrder": "Order Book",
		"buy": "Buy",
		"buying": "Buying",
		"limit": "Limit Order",
		"margin": "Margin",
		"marginAvailable": "Margin Available: ",
		"currentRate": "Current leverage: ",
		"marginRatio": "Margin Ratio",
		"market": "Market Order",
		"price": "Price",
		"quantity": "Quantity",
		"sell": "Sell",
		"selling": "Selling",
		"stopLoss": "Stop Loss",
		"takeProfit": "Take Profit",
		"volume": "Volume: "
	},
	"news": {},
	"notification": {
		"msg1": "Edit Successfully",
		"msg10": "Please Add Wallet",
		"msg11": "Please Switch Payment Method",
		"msg12": "Refresh",
		"msg13": "Please Add Bank Card",
		"msg14": "Settings Updated",
		"msg15": "Revise",
		"msg16": "Delete",
		"msg17": "Close Position",
		"msg18": "Success",
		"msg19": "Stop Loss must be greater than Spot Price",
		"msg2": "Submit Successfully",
		"msg20": "Take Profit must be greater than Spot Price",
		"msg21": "Order Price Cannot Be Empty",
		"msg22": "Success",
		"msg3": "Add Successfully",
		"msg4": "Register Successfully",
		"msg5": "Delete Successfully",
		"msg6": "Upload Successfully",
		"msg7": "Confirm",
		"msg8": "Cancel",
		"msg9": "Confirm Deleting Wallet",
		"msg23": "Verification Code Sent"
	},
	"orderHistory": {
		"cancel": "Cancel Order",
		"close": "Close",
		"closedOrders": "Closed Orders",
		"closing": "Closing",
		"commissionedOrder": "Commissioned Order",
		"endTime": "End Date",
		"openOrders": "Open Orders",
		"operate": "Operate",
		"orderHistory": "Order History",
		"startTime": "Start Date",
		"thead": [
			"Pair Code",
			"Order Number",
			"Type of Purchase",
			"Opening Price",
			"Opening Time",
			"Market Price",
			"Stop Price",
			"Take Profit Price",
			"Fee",
			"Unrealized Profit and Loss",
			"Commission Price",
			"Commision Time",
			"Closing Price",
			"Closing Time",
			"Transaction Time",
			"Transaction Price",
			"Transaction Quantity",
			"Amount",
			"Profit/Loss"
		]
	},
	"password": {
		"one": "Key in Original Password",
		"oneName": "Original Password",
		"three": "Key in New Password",
		"threeName": "Key in New Password Again",
		"two": "Key in New Password",
		"twoName": "New Password"
	},
	"personLeft": {
		"bank": "Bank Card",
		"information": "Personal Infomation",
		"news": "Notifications",
		"order": "Order History",
		"recharge": "Deposit",
		"recordIn": "Deposit History",
		"recordOut": "Withdrawal History",
		"updatePassword": "Change Password",
		"verified": "Verification",
		"wallet": "Virtual Wallet",
		"withdraw": "Withdraw",
		"invite": "invite friends"
	},
	"personTitle": {
		"edit": "Edit",
		"emailTitle": "Email Address",
		"idCard": "Identification Number",
		"idCardFront": "Front of Identification Card",
		"idCardFront1": "Back of Identification Card",
		"name": "Full Name",
		"nameTitle": "Username",
		"phoneTitle": "Phone Number",
		"country": "Country",
		"status": "Not Verified",
		"status0": "Verification in Process",
		"status1": "Verification Successful",
		"status2": "Verification Denied",
		"status3": "Not Verified",
		"submit": "Submit"
	},
	"readVer": {
		"email": "To Change Contact Infomation, Please Contact Customer Service",
		"idCard": "Key in Identification Number",
		"name": "Key in Username",
		"errerLog": "Verification code error",
		"codeOk": "Captcha is available",
	},
	"register": {
		"getVerification": "Send OTP",
		"inpTips1": "Email Address",
		"inpTips2": "Password",
		"inpTips3": "One Time Password (OTP)",
		"inpTips4": "Invitation Code",
		"login": "Log In",
		"msg1": "Agree",
		"msg2": "Terms and Conditions",
		"msg3": "Already Have An Account",
		"reg1": "Key in Email Address",
		"reg2": "Key in Password",
		"reg3": "Key in OTP",
		"reg4": "Key in Invitation Code",
		"reg5": "Send Complete",
		"register": "Register Account",
		"resetVerification": "Resend"
	},
	"reset": {
		"btn": "Reset",
		"getSend": "Send OTP",
		"inpTips": "Key in Email Address",
		"msg1": "Remember Password?",
		"msg2": "Log In Here",
		"reset": "Reset Password"
	},
	"time": {
		"15min": "15 Minute",
		"1H": "1 Hour",
		"1min": "1 Minute",
		"30min": "30 Minute",
		"4H": "4 Hour",
		"5day": "5 Day",
		"5min": "5 Minute",
		"day": "Daily",
		"moon": "Monthly",
		"second": "Minute",
		"week": "Weekly"
	},
	"userWith": {
		"eightName": "Receiver's Account",
		"fiveName": "Bank Address",
		"fourName": "Bank Name",
		"nineName": "Withdraw Amount",
		"one": "Key in Withdraw Amount",
		"oneName": "International Transaction",
		"sevenName": "Receiver's Name",
		"sixName": "SWIFT",
		"tenFour": "Wallet Barcode",
		"tenName": "Receiving Amount",
		"tenOne": "Upload Proof of Payment",
		"tenThree": "Wallet Address",
		"tenTwo": "Currency Network",
		"threeName": "Receiving Currency",
		"two": "Exact Receiving Amount",
		"twoName": "Cryptocurrency"
	},
	"wallet": {
		"add": "Add Wallet",
		"delete": "Delete Wallet",
		"fourName": "Wallet Barcode",
		"one": "Key in Wallet Network (i.e TRC-20)",
		"oneName": "Currency Type",
		"threeName": "Wallet Address",
		"two": "Key in Wallet Address",
		"twoName": "Currency Network"
	}
}