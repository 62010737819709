// import { getToken,setToken } from "@/utils/auth";

const ws = {
  state: {
    socket_status: 0
  },
  mutations: {
    set_socket_status: (state, value) => {
      state.socket_status = value;
    }
  },
  actions: {
    set_socket_status({ commit }, data) {
      commit("set_socket_status", data);
    }
  }
};

export default ws;
