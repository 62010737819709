import tw from './tw' // 繁体
import en from './en' // 英语
import vi from './vi' // 越南
import jp from './jp' // 日本

import th from './th' // 泰国
import kr from './kr' // 韩国
import fr from './fr' // 法国
import de from './de' // 德国
import br from './br' // 葡萄牙语巴西
import ru from './ru' // 俄语
import my from './my' // 马来西亚
import it from './it' // 意大利

export default {
    tw,
    en,
    vi,
    jp,
    th,
    kr,
    fr,
    de,
    br,
    ru,
    my,
    it
}