// import Vue from 'vue'
// import Vuex from 'vuex'
// import * as Cookies from 'js-cookie';
import ws from './modules/ws'
import common from './modules/common'
import user from './modules/user'
import theme from './modules/theme'
import lang from './modules/lang'
import production from './modules/production'
import getters from './getters'
import createPersistedState from "vuex-persistedstate";

// Vue.use(Vuex)
import { createStore } from "vuex";
const store = createStore({
  modules: {
    ws,
    common,
    user,
    theme,
    lang,
    production
  },
  getters,
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],

  strict: false //env_dev
})

// storage: {
//   getItem: (key) => {
//       // See https://nuxtjs.org/guide/plugins/#using-process-flags
//       if (process.server) {
//           const parsedCookies = cookie.parse(req.headers.cookie);
//           return parsedCookies[key];
//       } else {
//           return Cookies.get(key);
//       }
//   },
//   // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
//   setItem: (key, value) =>
//       Cookies.set(key, value, { expires: 365, secure: false }),
//   removeItem: key => Cookies.remove(key)
// }

export default store
