const getters = {
  theme: state => state.theme.theme,
  // 交易商
  get_common_api: state => state.common.commonApi,
  get_common_img: state => state.common.commonImg,
  get_common_ws: state => state.common.commonWs,
  get_common_name: state => state.common.commonName,
  get_common_link: state => state.common.commonLink,
  get_common_api_list: state => state.common.commonApiList,
  
  // 当前网站是否处于后台运行中
  get_vue_hidden_run: state => state.common.vue_hidden_run,
  //心跳返回数据间隔时间
  get_head_beet_time: state => state.common.headBeetTime,

  // 客服,k线时区
  get_dealerDetail: state => state.common.dealerDetail,

  // 用户
  get_margin: state => state.user.margin, // 保证金
  get_totalProfit: state => state.user.totalProfit, // 净值
  get_feeProfit: state => state.user.feeProfit, // 保证金比例
  get_canMargin: state => state.user.canMargin, // 可用保证金
  get_leverageRatio: state => state.user.leverageRatio, // 当前杠杆

  token: state => state.user.token,
  get_user_info: state => state.user.userInfo,
  get_lang: state => state.lang.lang,
  get_socket_status: state => state.ws.socket_status,
  get_market_pairs: state => state.production.marketPairs,
  get_current_production: state => state.production.currentProduction
};

export default getters;
