// import { getToken,setToken } from "@/utils/auth";

const production = {
  state: {
    currentProduction: '', // 当前选择产品
    marketPairs: [] // 产品列表
  },
  mutations: {
    set_current_production: (state, value) => {
      state.currentProduction = value;
    },
    set_market_pairs: (state, value) => {
      state.marketPairs = value;
    }
  },
  actions: {
    set_current_production({ commit }, data) {
      commit("set_current_production", data);
    },
    set_market_pairs({ commit }, data) {
      commit("set_market_pairs", data);
    }
  }
};

export default production;
