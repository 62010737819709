const getOss = ()=>{
  return new Promise((resolve, reject) => {
    let url = window.location.protocol+'//'+window.location.host+'/'
      // 交易商域名
      // console.error(url,'==============')
    // fetch(process.env.VUE_APP_BASE_API_DOMAIN+'/jsonLine/lineLoad.json')
    // fetch('https://broker.aifx.me/jsonLine/lineLoad.json')
    fetch(url+'/jsonLine/lineLoad.json')
    .then((response) => response.json())
    .then((json) => {
      let arr = json.map(item => url+item.line)
      let arr_img = json.map(item => url+item.img)
      let arr_ws = json.map(item => item.ws)
      let arr_name = json.map(item => item.name)
      let arr_link = json.map(item => item.link)

      let get_common_api = sessionStorage.getItem("get_common_api")
      let get_common_img = sessionStorage.getItem("get_common_img")
      let get_common_ws = sessionStorage.getItem("get_common_ws")
      let get_common_name = sessionStorage.getItem("get_common_name")
      let get_common_link = sessionStorage.getItem("get_common_link")
      // let get_common_api_list = sessionStorage.getItem("get_common_api_list")

      let dealers, dealersImg, dealersWs, dealersName, dealersLink = ''
      let dataList = json||[]
      if(!arr.includes(get_common_api)||!arr_img.includes(get_common_img)||!arr_ws.includes(get_common_ws)||!arr_name.includes(get_common_name)||!arr_link.includes(get_common_link)) {
        dealers = dataList.length>0?url+dataList[0].line:''
        dealersImg = dataList.length>0?url+dataList[0].img:''
        dealersWs = dataList.length>0?dataList[0].ws:''
        dealersName = dataList.length>0?dataList[0].name:''
        dealersLink = dataList.length>0?dataList[0].link:''
      } else {
        dealers = get_common_api
        dealersImg = get_common_img
        dealersWs = get_common_ws
        dealersName = get_common_name
        dealersLink = get_common_link
      }

      sessionStorage.setItem("get_common_api_list", JSON.stringify(dataList))
      sessionStorage.setItem("get_common_api", dealers)
      sessionStorage.setItem("get_common_img", dealersImg)
      sessionStorage.setItem("get_common_ws", dealersWs)
      sessionStorage.setItem("get_common_name", dealersName)
      sessionStorage.setItem("get_common_link", dealersLink)
      resolve()
    }).catch(error => {
      reject(error)
    })
  })
} 

export default getOss;