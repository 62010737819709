import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/css/ionicons.min.css";
import "./assets/scss/style.scss";
import router from "./router";
import store from "./store";
import { i18n } from './utils/i18n'
import elementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import getOss from "./oss.js";
import getOss_dev from "./oss_dev.js";

const calc = require('calculatorjs')
const app = createApp(App)
if(process.env.NODE_ENV === 'development') {
    // 交易商域名
    getOss_dev().then(()=>{
        app.config.globalProperties.$mathjs = calc
        app.use(elementPlus).use(store).use(router).use(i18n).mount('#app')
    })
} else if(process.env.NODE_ENV === 'production') {
    // 交易商域名
    getOss().then(()=>{
        app.config.globalProperties.$mathjs = calc
        app.use(elementPlus).use(store).use(router).use(i18n).mount('#app')
    })
}


