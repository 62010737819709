import request from "../../utils/request"
const common = {
  state: {
    commonApi: '', // 交易商域名
    commonImg: '', // 图片域名
    commonWs: '', // ws域名
    commonName: '', // 商域名名称
    commonLink: '', // 推广注册页地址
    commonApiList: [],
    vue_hidden_run: false, // 当前网站是否处于后台运行中
    headBeetTime: '', //心跳返回数据间隔时间
    dealerDetail: {
      customerLink: '', // 客服
      domainLink: '', // 官网链接
      versionNum: '', // 版本号
      dealerDomainLink: '', // 交易商官网
      link: '', // 推广域名
      timeZone: '' // K线时区
    }
  },
  mutations: {
    SET_COMMONAPI: (state, api) => {
      state.commonApi = api;
    },
    SET_COMMONIMG: (state, url) => {
      state.commonImg = url;
    },
    SET_COMMONWS: (state, ws) => {
      state.commonWs = ws;
    },
    SET_COMMONNAME: (state, name) => {
      state.commonName = name;
    },
    SET_COMMONLINK: (state, link) => {
      state.commonLink = link;
    },
    SET_COMMONAPILIST: (state, list) => {
      state.commonApiList = list;
    },
    SET_VUEHIDDENRUN: (state, vue_hidden_run) => {
      state.vue_hidden_run = vue_hidden_run;
    },
    SET_HEADBEETTIME: (state, headBeetTime) => {
      state.headBeetTime = headBeetTime;
    },
    SET_DEALER: (state, dealerDetail) => {
      state.dealerDetail.customerLink = dealerDetail.customerLink||''
      state.dealerDetail.domainLink = dealerDetail.domainLink||''
      state.dealerDetail.versionNum = dealerDetail.versionNum||''
      state.dealerDetail.dealerDomainLink = dealerDetail.dealerDomainLink||''
      state.dealerDetail.link = dealerDetail.link||''
      state.dealerDetail.timeZone = dealerDetail.timeZone||''
    }
  },
  actions: {
    setCommonApi({ commit }, api) {
      commit("SET_COMMONAPI", api);
      sessionStorage.setItem("get_common_api", api)
      // 设置交易商域名
      request.defaults.baseURL =  api;
    },
    setCommonImg({ commit }, url) {
      // 设置交易商图片域名
      commit("SET_COMMONIMG", url);
      sessionStorage.setItem("get_common_img", url)
    },
    setCommonWs({ commit }, ws) {
      // 设置交易商ws域名
      commit("SET_COMMONWS", ws);
      sessionStorage.setItem("get_common_ws", ws)
    },
    setCommonName({ commit }, name) {
      // 设置交易商名称
      commit("SET_COMMONNAME", name);
      sessionStorage.setItem("get_common_name", name)
    },
    setCommonLink({ commit }, link) {
      // 设置交易商名称
      commit("SET_COMMONLINK", link);
      sessionStorage.setItem("get_common_link", link)
    },
    setCommonApiList({ commit }, list) {
      commit("SET_COMMONAPILIST", list);
      sessionStorage.setItem("get_common_api_list", JSON.stringify(list))
    },
    set_vue_hidden_run({ commit }, data) {
      commit("SET_VUEHIDDENRUN", data);
    },
    setHeadBeetTime({ commit }, headBeetTime) {
      commit("SET_HEADBEETTIME", headBeetTime);
    },
    setDealerDetail({ commit }, dealerDetail) {
      commit("SET_DEALER", dealerDetail||{});
  }
  }
};

export default common;
