export default {
	"bank": {
		"five": "Vui lòng nhập SWIFT",
		"fiveName": "Địa chỉ thẻ ngân hàng",
		"four": "Vui lòng nhập địa chỉ ngân hàng thụ hưởng",
		"fourName": "Số thẻ ngân hàng",
		"one": "Vui lòng nhập tên người nhận",
		"oneName": "Loại tiền tệ",
		"three": "Vui lòng nhập số tài khoản nhận tiền",
		"threeName": "Tên thẻ ngân hàng",
		"two": "Vui lòng nhập tên ngân hàng thụ hưởng",
		"twoName": "Tên",
        "delete":"xóa thẻ ngân hàng"
	},
	"common": {
		"about": "Về chúng tôi",
		"logout": "Đăng xuất",
		"noData": "Không có dữ liệu",
		"search": "Tìm kiếm",
		"systemSet": "Cài đặt",
		"title": "Tiếng Việt"
	},
	"depositWith": {
		"eightName": "Tài khoản thụ hưởng",
		"fiveName": "Địa chỉ ngân hàng",
		"fourName": "Tên ngân hàng",
		"nineName": "Số tiền nạp",
		"one": "Vui lòng nhập số tiền nạp",
		"oneName": "Chuyển tiền quốc tế",
		"sevenName": "Người thụ hưởng",
		"sixName": "SWITF",
		"tenFour": "Mã QR ví tiền",
		"tenName": "Số tiền thanh toán",
		"tenOne": "Chứng từ thanh toán tải lên",
		"tenThree": "Địa chỉ ví",
		"tenTwo": "Mạng tiền tệ",
		"threeName": "Loại tiền tệ",
		"two": "Số tiền thanh toán thực tế",
		"twoName": "Tiền kỹ thuật số",
        "czName": "gửi nạp tiền",
        "txName": "gửi rút tiền"
	},
	"information": {
		"email": "Vui lòng liên hệ với chăm sóc khách hàng để yêu cầu sửa đổi",
		"name": "Hãy nhập một nickname",
		"phone": "Vui lòng nhập số điện thoại"
	},
	"inOutMoney": {
		"fiveName": "Số tiền",
		"fourName": "Thất bại",
		"oneName": "Tất cả",
		"sevenName": "Thời gian",
		"sixName": "Tình trạng",
		"threeName": "Thành công",
		"twoName": "Đang xét duyệt"
	},
	"login": {
		"forget": "Quên mật khẩu",
		"inpTips1": "Địa chỉ email",
		"inpTips2": "Mật khẩu",
		"login": "Đăng nhập",
		"reg1": "Vui lòng nhập email của bạn",
		"reg2": "Xin vui lòng nhập mật khẩu",
		"register": "Đăng ký",
		"remember": "Nhớ mật khẩu",
		"tipMsg": "Không tài khoản",
		"demo": "tài khoản demo",
	},
	"marketPairs": {
		"collection": "Bộ sưu tập của tôi",
		"pairs": "Mã hàng hóa",
		"purchasePrice": "Giá mua",
		"sellPrice": "Giá bán",
		"tradeType": "Loại hàng giao dịch"
	},
	"marketTrade": {
		"balance": "Còn lại: ",
		"bookOrder": "Đặt hàng",
		"buy": "Mua lên",
		"buying": "Đang mua lên",
		"limit": "Giá đặt hàng giao phó",
		"margin": "Quỹ đảm bảo",
		"marginAvailable": "Quỹ đảm bảo khả dụng: ",
		"marginRatio": "Tỷ lệ ký quỹ",
		"market": "Đơn giá thị trường",
		"price": "Giá",
		"quantity": "Số lượng",
		"sell": "Mua xuống",
		"selling": "Đang mua xuống",
		"stopLoss": "Cắt lỗ",
		"takeProfit": "Thu lợi nhuận",
		"volume": "Giá trị ròng: ",
		"currentRate": "đòn bẩy hiện tại: ",
	},
	"news": {},
	"notification": {
		"msg1": "Sửa đổi thành công",
		"msg10": "Vui lòng thêm ví",
		"msg11": "Vui lòng chuyển phương thức thanh toán",
		"msg12": "Làm mới",
		"msg13": "Vui lòng thêm thẻ ngân hàng",
		"msg14": "Thiết lập lại thành công",
		"msg15": "Sửa đổi",
		"msg16": "Xóa",
		"msg17": "Đóng lệnh",
		"msg18": "Thao tác thành công",
		"msg19": "Giá cắt lỗ phải lớn hơn giá mở cửa",
		"msg2": "Gửi thành công",
		"msg20": "Giá chốt lời phải thấp hơn giá mở cửa",
		"msg21": "Giá đặt hàng không được để trống",
		"msg22": "Thao tác thành công",
		"msg3": "Thêm thành công",
		"msg4": "Đăng ký thành công",
		"msg5": "Xóa thành công",
		"msg6": "Tải lên thành công",
		"msg7": "Chắc chắn",
		"msg8": "Hủy bỏ",
		"msg9": "Bạn có chắc chắn xóa ví",
		"msg23": "mã xác minh đã gửi"
	},
	"orderHistory": {
		"cancel": "Hủy đơn",
		"close": "Đóng lệnh",
		"closedOrders": "Hoàn thành lệnh",
		"closing": "Đang đóng lệnh",
		"commissionedOrder": "Giá đặt hàng giao phó",
		"endTime": "Ngày kết thúc",
		"openOrders": "Giữ lệnh đặt hàng",
		"operate": "Thao tác",
		"orderHistory": "Lịch sử đặt hàng",
		"startTime": "Ngày bắt đầu",
		"thead": [
			"Mã hàng",
			"Số đơn hàng",
			"Loại mua hàng",
			"Giá mở",
			"Thời gian mở",
			"Giá hiện tại",
			"Giá cắt lỗ",
			"Chốt lãi",
			"Phí giao dịch",
			"Lãi lỗ thả nổi",
			"Giá giao phó",
			"Thời gian giao phó",
			"Giá đóng",
			"Thời gian đóng",
			"Thời gian hoàn thành",
			"Giá hoàn thành",
			"Số lượng hoàn thành",
			"Số lượng",
			"Lợi nhuận, thua lỗ"
		]
	},
	"password": {
		"one": "Vui lòng nhập mật khẩu ban đầu",
		"oneName": "Mật khẩu ban đầu",
		"three": "Vui lòng nhập lại mật khẩu mới",
		"threeName": "Nhập lại mật khẩu",
		"two": "Vui lòng nhập mật khẩu mới",
		"twoName": "Mật khẩu mới"
	},
	"personLeft": {
		"bank": "Thẻ ngân hàng",
		"information": "Thông tin cá nhân",
		"news": "Thông báo",
		"order": "Ghi chép đặt hàng",
		"recharge": "Nạp tiền",
		"recordIn": "Ghi chép tiền gửi",
		"recordOut": "Ghi chép rút tiền",
		"updatePassword": "Thay đổi mật khẩu",
		"verified": "Đã xác minh",
		"wallet": "Ví ảo",
		"withdraw": "Rút",
		"invite": "mời bạn bè"
	},
	"personTitle": {
		"edit": "Sửa đổi",
		"emailTitle": "Mail",
		"idCard": "Số chứng minh",
		"idCardFront": "Mặt trước chứng minh thư",
		"idCardFront1": "Mặt sau chứng minh thư",
		"name": "Tên",
		"nameTitle": "Tên nick",
		"phoneTitle": "Số điện thoại",
		"country": "Quốc gia",
		"status": "Chưa chứng nhận",
		"status0": "Đang chứng nhận",
		"status1": "Đã chứng nhận",
		"status2": "Từ chối chứng nhận",
		"status3": "Chưa chứng nhận",
		"submit": "Gửi"
	},
	"readVer": {
		"email": "Vui lòng liên hệ với chăm sóc khách hàng để sửa đổi",
		"idCard": "Vui lòng số chứng minh nhân dân",
		"name": "Vui lòng nhập tên",
		"errerLog": "Lỗi mã xác minh",
		"codeOk": "hình ảnh xác thực có sẵn",
	},
	"register": {
		"getVerification": "Nhận mã xác nhận",
		"inpTips1": "Mail",
		"inpTips2": "Mật khẩu",
		"inpTips3": "Mã xác nhận",
		"inpTips4": "Mã mời",
		"login": "Đăng nhập",
		"msg1": "Tôi đồng ý",
		"msg2": "Các điều khoản và điều kiện",
		"msg3": "Đã có tài khoản",
		"reg1": "Vui lòng nhập địa chỉ mail",
		"reg2": "Vui lòng nhập địa chỉ",
		"reg3": "Vui lòng nhập mã xác nhận",
		"reg4": "Vui lòng nhập mã mời",
		"reg5": "Gửi thành công",
		"register": "Đăng ký tài khoản",
		"resetVerification": "Nhận lại"
	},
	"reset": {
		"btn": "Cài đặt lại",
		"getSend": "Nhận mã chứng nhận",
		"inpTips": "Vui lòng nhập địa chỉ mail của bạn",
		"msg1": "Ghi nhớ mật khẩu của bạn?",
		"msg2": "Đăng nhập ở đây",
		"reset": "Cài đặt lại mật khẩu"
	},
	"time": {
		"15min": "15 phút",
		"1H": "1 giờ",
		"1min": "1 phút",
		"30min": "30 phút",
		"4H": "4 giờ",
		"5day": "5 ngày",
		"5min": "5 phút",
		"day": "Hàng ngày",
		"moon": "Hàng tháng",
		"second": "Phút",
		"week": "Hàng tuần"
	},
	"userWith": {
		"eightName": "Tài khoản hưởng thụ",
		"fiveName": "Địa chỉ ngân hàng",
		"fourName": "Tên ngân hàng",
		"nineName": "số tiền rút",
		"one": "Vui lòng nhập số tiền rút",
		"oneName": "Chuyển tiền quốc tế",
		"sevenName": "Người thụ hưởng",
		"sixName": "SWITF",
		"tenFour": "Mã QR ví tiền",
		"tenName": "Số tiền vào tài khoản",
		"tenOne": "Tải lên chứng từ thanh toán",
		"tenThree": "Địa chỉ ví",
		"tenTwo": "Mạng tiền tệ",
		"threeName": "Loại tiền nhận",
		"two": "Số tiền thực nhận",
		"twoName": "Tiền kỹ thuật số"
	},
	"wallet": {
		"add": "Thêm ví tiền",
		"delete": "Xóa ví tiền",
		"fourName": "Mã QR ví tiền",
		"one": "Vui lòng nhập mạng tiền tệ (ví dụ: TRC20)",
		"oneName": "Loại tiền tệ",
		"threeName": "Địa chỉ ví",
		"two": "Vui lòng nhập địa chỉ ví",
		"twoName": "Mạng tiền tệ"
	}
}